<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <!-- <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="routeToAddJob()"></button>
                    </div>
                </ng-template>

                <ng-template pTemplate="right">
                </ng-template>
            </p-toolbar>

            <p-dataView #dv [value]="jobs" [paginator]="true" [rows]="9" layout="grid">

                <ng-template pTemplate="header">
					<div class="grid grid-nogutter">
						<div class="col-6 text-left">
                            <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="routeToAddJob()"></button>
                            
                           
                        </div>
						<div class="col-6 text-right">
							<p-dataViewLayoutOptions></p-dataViewLayoutOptions>
						</div>
                        <div class="col-12 mt-2">
                            <p-dropdown placeholder="Select Client" [options]="clients" optionLabel="name"></p-dropdown>
                            <p-dropdown class="ml-2" placeholder="Select Status" [options]="status" optionLabel="name"></p-dropdown>
                            
                            <div class="mx-2" style="display: inline;">
                                <span class="mr-2 font-medium">From Date : <input pInputText type="date" [(ngModel)]="date" name="createdDate" placeholder="Select Date" /></span> 
                                <span class="mx-2 font-medium">To Date :</span> <input pInputText type="date" [(ngModel)]="date" name="createdDate" placeholder="Select Date" />
                            </div>
                        </div>
					</div>
				</ng-template>

                <ng-template let-job let-i="rowIndex" pTemplate="listItem">
					<div class="col-12">
						<div class="flex flex-column md:flex-row align-items-center p-3 w-full">
							<div class="flex-1 text-center md:text-left">
								<div class="font-bold flex text-xl">
                                    <span class="mr-2">#{{i+1}}</span>  
                                    <p-tag *ngIf="job.jobPriority == 'HIGH'"  rounded="true" severity="danger" value="High"></p-tag>
                                    <p-tag *ngIf="job.jobPriority == 'MEDIUM'" rounded="true" severity="primary" value="Medium"></p-tag>
                                    <p-tag *ngIf="job.jobPriority == 'LOW'" rounded="true" severity="success" value="Low"></p-tag>
                                </div>
								<div class="flex align-items-center mt-3">
									<i class="pi pi-map-marker mr-2"></i>
									<span class="font-semibold">Pickup</span>
								</div>
                                <div class="flex align-items-center mt-2">
									<span class="ml-4 font-semibold"><span class="font-semibold spots">{{job.pickupSpot?.spotName}}</span></span>
								</div>
                                <div class="flex align-items-center mt-2">
									<span class="ml-4">{{job.pickupLocation?.locationName}}, {{job.pickupLocation?.street}}, {{job.pickupLocation?.city}}, {{job.pickupLocation?.state}}, {{job.pickupLocation?.zip}}</span>
								</div>
								<div class="flex align-items-center mt-3">
									<i class="pi pi-map-marker mr-2"></i>
									<span class="font-semibold">Drop</span>
								</div>
                                <div class="flex align-items-center mt-2">
									<span class="ml-4 font-semibold"><span class="font-semibold spots">{{job.dropSpot?.spotName}}</span></span>
								</div>
                                <div class="flex align-items-center mt-2">
									<span class="ml-4">{{job.dropLocation?.locationName}}, {{job.dropLocation?.street}}, {{job.dropLocation?.city}}, {{job.dropLocation?.state}}, {{job.dropLocation?.zip}}</span>
								</div>
                                <div class="flex align-items-center mt-3">
									<i class="pi pi-list mr-2"></i>
									<span class="font-semibold">Description</span>
								</div>
                                <div class="flex align-items-center mt-2">
									<span class="ml-4">{{job.description}}</span>
								</div>
                                <div class="flex align-items-center mt-3">
									<i class="pi pi-car mr-2"></i>
									<span class="font-semibold">Trailer/Truck</span>
								</div>
                                <div class="flex align-items-center mt-2">
									<span class="ml-4">{{job.fleet?.unitNumber}}</span>
								</div>
                                <div class="flex align-items-center mt-3">
									<i class="pi pi-bell mr-2"></i>
									<span class="font-semibold">Status</span>
								</div>
                                <div class="flex align-items-center mt-2">
									<span class="ml-4">{{job?.status}}</span>
								</div>
                                <div class="flex align-items-center mt-3">
									<i class="pi pi-user mr-2"></i>
									<span class="font-semibold">Assigned To</span>
								</div>
                                <div class="flex align-items-center mt-2">
									<span class="ml-4">{{job.assignedTo?.firstName}} {{job.assignedTo?.lastName}}</span>
								</div>
							</div>
						</div>
					</div>
				</ng-template>


				<ng-template let-job let-i="rowIndex" pTemplate="gridItem">
					<div class="col-12 md:col-4">
						<div class="card m-3 border-1 surface-border job-hover">
                            <div class="flex justify-content-between card-header mb-3">
                                <span class="flex font-semibold">#{{i+1}}</span>
                                <p-tag *ngIf="job.priority == 'HIGH'" class="flex" rounded="true" severity="danger" value="High"></p-tag>
                                <p-tag *ngIf="job.priority == 'MEDIUM'" class="flex" rounded="true" severity="primary" value="Medium"></p-tag>
                                <p-tag *ngIf="job.priority == 'LOW'" class="flex" rounded="true" severity="success" value="Low"></p-tag>
                            </div>
                            <hr>
							<div class="flex flex-column">
								<div class="flex justify-content-between">
                                    <div class="flex">
                                    <i class="pi pi-map-marker mr-2"></i>
									<span class="font-semibold">Pickup</span>
                                    </div>
                                    <div class="flex">
                                        <span class="font-semibold spots">{{job.pickupSpot?.spotName}}</span>
                                    </div>
								</div>
                                <div class="flex mt-1 flex-column">
                                    <small>{{job.pickupLocation?.locationName}}</small>
                                    <small>{{job.pickupLocation?.street}}, {{job.pickupLocation?.city}}, {{job.pickupLocation?.state}}, {{job.pickupLocation?.zip}}</small>
                                </div>
							</div>
                            <div class="flex flex-column mt-3">
                                <div class="flex justify-content-between">
								<div class="flex">
                                    <i class="pi pi-map-marker mr-2"></i>
									<span class="font-semibold">Drop</span>
								</div>
                                <div class="flex">
                                    <span class="font-semibold spots">{{job.dropSpot?.spotName}}</span>
                                </div>
                                </div>
                                <div class="flex mt-1 flex-column">
                                    <small>{{job.dropLocation?.locationName}}</small>
                                    <small>{{job.dropLocation?.street}}, {{job.dropLocation?.city}}, {{job.dropLocation?.state}}, {{job.dropLocation?.zip}}</small>
                                </div>
							</div>
                            <hr>
							<div class="mt-3">
								<small class="mb-2">{{job.description}}</small>
							</div>
                            <hr>
                            <div class="mt-3">
								<small class="mb-2"> <b>Trailer/Truck:</b> {{job.fleet?.unitNumber}}</small>
							</div>
                            <hr>
                            <div class="flex justify-content-between">
                                <div class="flex" style="align-items: flex-start;">
                                    <small> <b>Status:</b> <p-tag class="mx-2" severity="warning" [value]="job?.status"></p-tag></small>
                                </div>
                                <div class="flex flex-column align-items-end">
                                
                                    <div class="flex">
                                        <span class="font-semibold font-medium">Assigned To:</span>
                                    </div>
                                    <div class="flex mt-1">
                                        <small>{{job.assignedTo?.firstName}} {{job.assignedTo?.lastName}}</small>
                                    </div>
                                </div>
                            </div>
						</div>
					</div>
				</ng-template>
			</p-dataView>
        </div> -->

        <div class="card">
            <p-toast></p-toast>

            <p-toolbar>
                <div class="w-full">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <div class="my-2">
                            <h5 class="m-0">Manage Spots</h5>
                        </div>

                        <div>
                            <button pButton pRipple label="New" icon="pi pi-plus" 
                                class="p-button-success mr-2" (click)="routeToAddJob()"></button>
                            <!-- <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportExcel()"></button> -->
                            <button pButton pRipple label="Export" 
                            class="p-button-raised p-button-help mr-2" (click)="exportPopup()"></button>
                            <!-- <p-splitButton label="Export" [model]="items" (onClick)="exportExcel()"
                                styleClass="p-button-raised p-button-help mr-2 "></p-splitButton> -->
                        </div>
                    </div>


                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center mrgt-30">
                        <span>

                            <p-dropdown *ngIf="userType !== 'ROLE_CLIENT'" [showClear]="true"
                                placeholder="Select Client" [options]="clientList"
                                (onChange)="filterJobsByClient($event)" optionLabel="clientName" optionValue="clientId"
                                class="mrgr-10"></p-dropdown>

                            <p-autoComplete placeholder="Filter By Trailer/Unit#" [(ngModel)]="filterFleetId"
                                [suggestions]="filteredFleets" (onSelect)="onFleetSelect($event)" dataKey="fleetId"
                                (completeMethod)="filterFleets($event)" [dropdown]="true"
                                (onClear)="clearFilter($event)" class="mrgr-10" field="fleetAndHotTrailer">
                                <ng-template let-fleet pTemplate="item">
                                    <div>{{fleet.fleetAndHotTrailer}}</div>
                                </ng-template>
                            </p-autoComplete>

                            <input pInputText type="text" [(ngModel)]="searchNotes" placeholder="Search Notes Or ASN"
                                (input)="searchInNotes()" />

                            <!-- <p-dropdown [showClear]="true" class="ml-2" placeholder="Select Status"
                                (onChange)="filterJobsByStatus($event)" [options]="status" optionLabel="name"
                                optionValue="code"></p-dropdown> -->

                            <!-- <div class="mx-2" style="display: inline;">
                                
                            </div> -->

                        </span>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <span class="mr-2 font-medium">From Date : <input pInputText type="date"
                                    [(ngModel)]="fromDate" (change)="filterJobsByDate()" name="fromDate"
                                    placeholder="Select Date" /></span>
                            <span class="mx-2 font-medium">To Date : <input pInputText type="date" [(ngModel)]="toDate"
                                    (change)="filterJobsByDate()" name="toDate" placeholder="Select Date" /></span>
                        </span>
                    </div>
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center mt-2">
                        <span>

                        </span>
                    </div>
                </div>
            </p-toolbar>
            <!-- <div class="mt-4 ml-4">
                <p class="mb-2"><span class="font-medium text-500">Open :</span> {{ totalRecordsOpen }}</p>
                <p class="mb-2"><span class="font-medium text-500">In-Transit :</span> {{ totalRecordsIntransit }} </p>
                <p class="mb-2"><span class="font-medium text-500">Exceptions :</span> {{ totalRecordsException }}</p>
            </div> -->
            <div class="mrgt-20">
                <p-accordion [activeIndex]="accordionActiveIndex">
                    <p-accordionTab header="Exceptions - {{ totalRecordsException }}" *ngIf="exceptionActive">
                        <p-table #dt4 [value]="jobsException" [loading]="loadingException"
                            styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true"
                            [columns]="cols" [rows]="10"
                            [globalFilterFields]="['pickupLocation','pickupSpot','dropLocation','dropSpot','jobNumber']"
                            [rowHover]="true" dataKey="id" [responsiveLayout]="'scroll'">

                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 70px;"></th>
                                    <th pSortableColumn="createdDate">
                                        <div style="display: flex;flex-direction: row;">
                                            Spot Creation Date
                                            <div class="flex justify-content-center">
                                                <p-overlayPanel #op>

                                                    <ng-template pTemplate="content" style="height: 2000px;">
                                                        <h4>Sort Created Date</h4>
                                                        <p-dropdown placeholder="Order By" [showClear]="true" [(ngModel)]="selectedSortDateException"
                                                            [options]="createdDateSort" optionLabel="name"
                                                            (onChange)="sortCreationDate($event,op,'EXCEPTION')"
                                                            appendTo="body">
                                                        </p-dropdown>
                                                    </ng-template>


                                                </p-overlayPanel>
                                                <i class="pi pi-filter" (click)="op.toggle($event)"></i>
                                            </div>
                                        </div>

                                    </th>
                                    <th pSortableColumn="jobPickupTime">Spot Pickup Time</th>
                                    <th pSortableColumn="jobDropoffTime">Spot Dropoff Time</th>
                                    <th pSortableColumn="jobNumber">
                                        Spot Number
                                        <!-- <p-columnFilter type="text" field="jobNumber" display="menu"></p-columnFilter> -->
                                    </th>
                                    <th pSortableColumn="jobPriority">
                                        Spot Priority
                                        <!-- <i class="pi pi-search" style="font-size: 1.5rem" (click)="demo($event)"></i> -->
                                    </th>
                                    <th pSortableColumn="pickupLocation">Pickup Location</th>
                                    <th pSortableColumn="dropLocation">Drop Location</th>
                                    <th pSortableColumn="description" style="width: 160px;">Notes</th>
                                    <th pSortableColumn="trailerTruck">Trailer/Container</th>
                                    <th pSortableColumn="status">Status</th>
                                    <th pSortableColumn="exception">Exceptions</th>
                                    <th pSortableColumn="assignedTo">
                                        <div style="display: flex;flex-direction: row;">
                                            Assigned To
                                            <div class="flex justify-content-center">
                                                <p-overlayPanel #op1>

                                                    <ng-template pTemplate="content">
                                                        <h4>Select User</h4>
                                                        <p-dropdown [options]="drivers" [showClear]="true"
                                                            [(ngModel)]="assignedDriverException" optionLabel="fullName"
                                                            placeholder="Select User"
                                                            (onChange)="sortByAssignedUser($event,op1,'EXCEPTION')"></p-dropdown>
                                                    </ng-template>


                                                </p-overlayPanel>
                                                <i class="pi pi-filter" (click)="op1.toggle($event)"></i>
                                            </div>
                                        </div>

                                    </th>
                                    <th pSortableColumn="createdBy">Created By</th>
                                    <th pSortableColumn="weather">Weather</th>
                                    <th pSortableColumn="sequenceAsn" *ngIf="isSupervisorOrClient">Sequence ASN</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-job>
                                <tr>
                                    <td>
                                        
                                        <button *ngIf="job.bols && job.bols.length > 0" pButton pRipple
                                            icon="pi pi-images" pTooltip="View BOL images"
                                            class="p-button-rounded p-button-secondary mr-2"
                                            (click)="viewBol(job.bols)"></button>

                                    </td>
                                    <td><span class="p-column-title">Spot Creation Date</span>
                                        {{job.audit?.createdDate}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Spot Pickup Time</span>
                                        {{job?.pickupDateTime ? job?.pickupDateTime : '-'}}
                                        <!-- <span *ngIf="job?.jobCompletionSeconds">(hh:mm:ss)</span> -->
                                    </td>
                                    <td>
                                        <span class="p-column-title">Spot Dropoff Time</span>
                                        {{job?.dropDateTime ? job?.dropDateTime : '-'}}
                                        <!-- <span *ngIf="job?.jobCompletionSeconds">(hh:mm:ss)</span> -->
                                    </td>
                                    <td><span class="p-column-title">Spot Number</span>
                                        {{job.jobNumber}}
                                    </td>
                                    <td class="text-center"><span class="p-column-title">Job Priority</span>
                                        <p-tag *ngIf="job.priority == 'HIGH'" rounded="true" severity="danger"
                                            value="High"></p-tag>
                                        <p-tag *ngIf="job.priority == 'MEDIUM'" rounded="true" severity="primary"
                                            value="Medium"></p-tag>
                                        <p-tag *ngIf="job.priority == 'LOW'" rounded="true" severity="success"
                                            value="Low"></p-tag>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Pickup Location</span>
                                        {{job.pickupLocation?.locationName}}, {{job.pickupLocation?.state}} - 
                                        <br/>
                                        {{job.pickupSpot?.spotName}}
                                    </td>
                                    
                                    <td>
                                        <span class="p-column-title">Drop Location</span>
                                        {{job.dropLocation?.locationName}}, {{job.dropLocation?.state}} - 
                                        <br/>
                                        {{job.dropSpot?.spotName}}
                                    </td>
                                    
                                    <td style="max-width: 100px; overflow: hidden;word-wrap: break-word;">
                                        <span class="p-column-title">Notes</span>
                                        <div *ngIf="job.description">
                                            <b>{{job.descriptionRole}}</b> {{job.description}}
                                        </div>
                                        <div *ngIf="job.pickupNotes">
                                            <b>{{job.pickupRole}}</b> {{job.pickupNotes}}
                                        </div>
                                        <div *ngIf="job.dropNotes">
                                            <b>{{job.dropRole}}</b> {{job.dropNotes}}
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Trailer/Container</span>
                                        {{job.fleet?.unitNumber}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Status</span>
                                        <p-tag *ngIf="job.status == 'OPEN'" rounded="true" severity="warning"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'IN_TRANSIT'" rounded="true" severity="primary"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'COMPLETED'" rounded="true" severity="success"
                                            [value]="job.status"></p-tag>
                                            <p-tag *ngIf="job.status == 'EXCEPTION'" rounded="true" severity="danger"
                                            [value]="job.status"></p-tag>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Exceptions</span>
                                        {{ job.trailerException }}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Assigned To</span>
                                        {{job.assignedTo?.firstName}} {{job.assignedTo?.lastName}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Created By</span>
                                        {{job.audit.createdBy?.firstName}} {{job.audit.createdBy?.lastName}}
                                    </td>
                                    <td>
                                        <div *ngIf="job.climate">
                                            <!-- <span class="p-column-title">Weather</span> -->
                                            {{job.climate}}
                                            <div>
                                                Temp:{{job.temperature}}&nbsp;F
                                            </div>

                                        </div>
                                    </td>
                                    <td *ngIf="isSupervisorOrClient">
                                        <span class="p-column-title">Sequence ASN</span>
                                        {{ job.sequenceAsn }}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="15">No spots found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <p-paginator [rows]="50" [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            [totalRecords]="totalRecordsException"
                            (onPageChange)="paginateExceptions($event,'EXCEPTION')" #paginator4></p-paginator>
                    </p-accordionTab>
                    <p-accordionTab header="Queue (Driver)- {{ totalRecordsDriverQueue }}">
                        <p-table #dt5 [value]="jobsDriverQueue" [loading]="loading"
                            styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true"
                            [columns]="cols" [rows]="10" reorderableRows="true" (onRowReorder)="onRowReorderFirstTable($event)"                          
                            [globalFilterFields]="['pickupLocation','pickupSpot','dropLocation','dropSpot','jobNumber']"
                            [rowHover]="true" dataKey="id" [responsiveLayout]="'scroll'">

                            <ng-template pTemplate="header">
                                <tr>
                                    <th *ngIf="filterByClient"></th>
                                    <th style="width: 70px;"></th>
                                    <th pSortableColumn="createdDate">
                                        <div style="display: flex;flex-direction: row;">
                                            Spot Creation Date
                                            <div class="flex justify-content-center">
                                                <p-overlayPanel #op>

                                                    <ng-template pTemplate="content" style="height: 2000px;">
                                                        <h4>Sort Created Date</h4>
                                                        <p-dropdown placeholder="Order By" [showClear]="true" [(ngModel)]="selectedSortDate"
                                                            [options]="createdDateSort" optionLabel="name"
                                                            (onChange)="sortCreationDate($event,op,'QUEUE','BUCKET_DRIVER')"
                                                            appendTo="body">
                                                        </p-dropdown>
                                                    </ng-template>


                                                </p-overlayPanel>
                                                <i class="pi pi-filter" (click)="op.toggle($event)"></i>
                                            </div>
                                        </div>

                                    </th>
                                    <th pSortableColumn="jobPickupTime">Spot Pickup Time</th>
                                    <th pSortableColumn="jobDropoffTime">Spot Dropoff Time</th>
                                    <th pSortableColumn="jobNumber">
                                        Spot Number
                                        <!-- <p-columnFilter type="text" field="jobNumber" display="menu"></p-columnFilter> -->
                                    </th>
                                    <th pSortableColumn="jobPriority">
                                        Spot Priority
                                        <!-- <i class="pi pi-search" style="font-size: 1.5rem" (click)="demo($event)"></i> -->
                                    </th>
                                    <th pSortableColumn="pickupLocation">Pickup Location</th>
                                    <!-- <th pSortableColumn="pickupSpot">Pickup Parking Spot</th> -->
                                    <th pSortableColumn="dropLocation">Drop Location</th>
                                    <th pSortableColumn="description" style="width: 160px;">Notes</th>
                                    <th pSortableColumn="trailerTruck">Trailer/Container</th>
                                    <th pSortableColumn="status">Status</th>
                                    <!-- <th pSortableColumn="exception">Exceptions</th> -->
                                    <th pSortableColumn="assignedTo">
                                        <div style="display: flex;flex-direction: row;">
                                            Assigned To
                                            <!-- <div class="flex justify-content-center">
                                                <p-overlayPanel #op1>

                                                    <ng-template pTemplate="content">
                                                        <h4>Select User</h4>
                                                        <p-dropdown [options]="drivers" [showClear]="true"
                                                            [(ngModel)]="assignedDriver" optionLabel="fullName"
                                                            placeholder="Select User"
                                                            (onChange)="sortByAssignedUser($event,op1,'OPEN')"></p-dropdown>
                                                    </ng-template>


                                                </p-overlayPanel>
                                                <i class="pi pi-filter" (click)="op1.toggle($event)"></i>
                                            </div> -->
                                        </div>

                                    </th>
                                    <th pSortableColumn="createdBy"><div style="display: flex;flex-direction: row;">
                                        Created By
                                        <div *ngIf="hideButtonsIfGuard == false" class="flex justify-content-center">
                                            <p-overlayPanel #op2>

                                                <ng-template pTemplate="content">
                                                    <h4>Select User</h4>
                                                    <p-dropdown [options]="allUsers" [showClear]="true"
                                                    [(ngModel)]="createdUserDriverQueue" optionLabel="fullName"
                                                        placeholder="Select User"
                                                        (onChange)="sortByCreatedUser($event,op2,'QUEUE','BUCKET_DRIVER')"></p-dropdown>
                                                </ng-template>


                                            </p-overlayPanel>
                                            <i class="pi pi-filter" (click)="op2.toggle($event)"></i>
                                        </div>
                                    </div></th>
                                    <th pSortableColumn="weather">Weather</th>
                                    <th pSortableColumn="sequenceAsn" *ngIf="isSupervisorOrClient">Sequence ASN</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-job let-columns="columns" let-index="rowIndex">
                                <tr [pReorderableRow]="index">
                                    <td *ngIf="filterByClient">
                                        <span class="pi pi-bars" pReorderableRowHandle></span> 
                                    </td>
                                    <td>
                                        <button
                                            *ngIf="(job.status !== 'COMPLETED' && job.status !== 'IN_TRANSIT') && userType !== 'ROLE_CLIENT'"
                                            pButton pRipple icon="pi pi-pencil" pTooltip="Edit"
                                            class="p-button-rounded p-button-help mr-2"
                                            (click)="routeToEdit(job.jobId,'QUEUE')"></button>
                                        <button
                                            *ngIf="job.status == 'OPEN' || job.status == 'QUEUE' && userType !== 'ROLE_CLIENT' && hideButtonsIfGuard === false && hideButtonsIfSpotter === false && hideButtonsIfDriver === false"
                                            pButton pRipple icon="pi pi-trash" pTooltip="Delete Job"
                                            class="p-button-rounded p-button-danger mr-2"
                                            (click)="deleteJob(job.jobId)"></button>
                                        <button *ngIf="job.status == 'OPEN' && userType !== 'ROLE_CLIENT'" pButton
                                            pRipple icon="pi pi-car" pTooltip="Confirm Pickup"
                                            class="p-button-rounded p-button-success mr-2"
                                            (click)="openStatusDialog(job,'Confirm Pickup')"></button>
                                        <button *ngIf="job.status == 'IN_TRANSIT' && userType !== 'ROLE_CLIENT'" pButton
                                            pRipple icon="pi pi-car" pTooltip="Confirm Drop"
                                            class="p-button-rounded p-button-warning mr-2"
                                            (click)="openStatusDialog(job,'Confirm Drop')"></button>
                                        <button *ngIf="job.bols && job.bols.length > 0" pButton pRipple
                                            icon="pi pi-images" pTooltip="View BOL images"
                                            class="p-button-rounded p-button-secondary mr-2"
                                            (click)="viewBol(job.bols)"></button>
                                        <!-- <button pButton pRipple icon="pi pi-exclamation-circle"
                                            pTooltip="Exception for moves"
                                            class="p-button-rounded p-button-secondary mr-2"
                                            (click)="exceptions(job.jobId)"></button> -->

                                    </td>
                                    <td><span class="p-column-title">Spot Creation Date</span>
                                        {{job.audit?.createdDate}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Spot Pickup Time</span>
                                        {{job?.pickupDateTime ? job?.pickupDateTime : '-'}}
                                        <!-- <span *ngIf="job?.jobCompletionSeconds">(hh:mm:ss)</span> -->
                                    </td>
                                    <td>
                                        <span class="p-column-title">Spot Dropoff Time</span>
                                        {{job?.dropDateTime ? job?.dropDateTime : '-'}}
                                        <!-- <span *ngIf="job?.jobCompletionSeconds">(hh:mm:ss)</span> -->
                                    </td>
                                    <td><span class="p-column-title">Spot Number</span>
                                        {{job.jobNumber}}
                                    </td>
                                    <td class="text-center"><span class="p-column-title">Job Priority</span>
                                        <p-tag *ngIf="job.priority == 'HIGH'" rounded="true" severity="danger"
                                            value="High"></p-tag>
                                        <p-tag *ngIf="job.priority == 'MEDIUM'" rounded="true" severity="primary"
                                            value="Medium"></p-tag>
                                        <p-tag *ngIf="job.priority == 'LOW'" rounded="true" severity="success"
                                            value="Low"></p-tag>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Pickup Location</span>
                                        {{job.pickupLocation?.locationName}}, {{job.pickupLocation?.state}} - 
                                        <br/>
                                        {{job.pickupSpot?.spotName}}
                                    </td>
                                    
                                    <td>
                                        <span class="p-column-title">Drop Location</span>
                                        {{job.dropLocation?.locationName}}, {{job.dropLocation?.state}} - 
                                        <br/>
                                        {{job.dropSpot?.spotName}}
                                    </td>
                                    
                                    <td style="max-width: 100px; overflow: hidden;word-wrap: break-word;">
                                        <span class="p-column-title">Notes</span>
                                        <div *ngIf="job.description">
                                            <b>{{job.descriptionRole}}</b> {{job.description}}
                                        </div>
                                        <div *ngIf="job.pickupNotes">
                                            <b>{{job.pickupRole}}</b> {{job.pickupNotes}}
                                        </div>
                                        <div *ngIf="job.dropNotes">
                                            <b>{{job.dropRole}}</b> {{job.dropNotes}}
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Trailer/Container</span>
                                        {{job.fleet?.unitNumber}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Status</span>
                                        <p-tag *ngIf="job.status == 'OPEN'" rounded="true" severity="warning"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'IN_TRANSIT'" rounded="true" severity="primary"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'COMPLETED'" rounded="true" severity="success"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'EXCEPTION'" rounded="true" severity="success"
                                            [value]="job.status"></p-tag>
                                    </td>
                                    <!-- <td>
                                        <span class="p-column-title">Exceptions</span>
                                        {{ job.trailerException }}
                                    </td> -->
                                    <td>
                                        <span class="p-column-title">Assigned To</span>
                                        {{job.assignedTo?.firstName}} {{job.assignedTo?.lastName}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Created By</span>
                                        {{job.audit.createdBy?.firstName}} {{job.audit.createdBy?.lastName}}
                                    </td>
                                    <td>
                                        <div *ngIf="job.climate">
                                            <!-- <span class="p-column-title">Weather</span> -->
                                            {{job.climate}}
                                            <div>
                                                Temp:{{job.temperature}}&nbsp;F
                                            </div>

                                        </div>
                                    </td>
                                    <td *ngIf="isSupervisorOrClient">
                                        <span class="p-column-title">Sequence ASN</span>
                                        {{ job.sequenceAsn }}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="16">No spots found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <p-paginator [rows]="50" [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            [totalRecords]="totalRecordsOpen" (onPageChange)="paginateDriverQueue($event,'QUEUE')"
                            #paginator5></p-paginator>
                    </p-accordionTab>
                    <p-accordionTab header="Queue (Spotter)- {{ totalRecordsSpotterQueue }}">
                        <p-table #dt6 [value]="jobsSpotterQueue" [loading]="loading"
                            styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true"
                            [columns]="cols" [rows]="10" reorderableRows="true" (onRowReorder)="onRowReorderSecondTable($event)"
                            [globalFilterFields]="['pickupLocation','pickupSpot','dropLocation','dropSpot','jobNumber']"
                            [rowHover]="true" dataKey="id" [responsiveLayout]="'scroll'">

                            <ng-template pTemplate="header">
                                <tr>
                                    <th *ngIf="filterByClient">
                                        
                                    </th>
                                    <th style="width: 70px;"></th>
                                    <th pSortableColumn="createdDate">
                                        <div style="display: flex;flex-direction: row;">
                                            Spot Creation Date
                                            <div class="flex justify-content-center">
                                                <p-overlayPanel #op>

                                                    <ng-template pTemplate="content" style="height: 2000px;">
                                                        <h4>Sort Created Date</h4>
                                                        <p-dropdown placeholder="Order By" [showClear]="true" [(ngModel)]="selectedSortDate"
                                                            [options]="createdDateSort" optionLabel="name"
                                                            (onChange)="sortCreationDate($event,op,'QUEUE', 'BUCKET_SPOTTER')"
                                                            appendTo="body">
                                                        </p-dropdown>
                                                    </ng-template>


                                                </p-overlayPanel>
                                                <i class="pi pi-filter" (click)="op.toggle($event)"></i>
                                            </div>
                                        </div>

                                    </th>
                                    <th pSortableColumn="jobPickupTime">Spot Pickup Time</th>
                                    <th pSortableColumn="jobDropoffTime">Spot Dropoff Time</th>
                                    <th pSortableColumn="jobNumber">
                                        Spot Number
                                        <!-- <p-columnFilter type="text" field="jobNumber" display="menu"></p-columnFilter> -->
                                    </th>
                                    <th pSortableColumn="jobPriority">
                                        Spot Priority
                                        <!-- <i class="pi pi-search" style="font-size: 1.5rem" (click)="demo($event)"></i> -->
                                    </th>
                                    <th pSortableColumn="pickupLocation">Pickup Location</th>
                                    <!-- <th pSortableColumn="pickupSpot">Pickup Parking Spot</th> -->
                                    <th pSortableColumn="dropLocation">Drop Location</th>
                                    <th pSortableColumn="description" style="width: 160px;">Notes</th>
                                    <th pSortableColumn="trailerTruck">Trailer/Container</th>
                                    <th pSortableColumn="status">Status</th>
                                    <!-- <th pSortableColumn="exception">Exceptions</th> -->
                                    <th pSortableColumn="assignedTo">
                                        <div style="display: flex;flex-direction: row;">
                                            Assigned To
                                            <!-- <div class="flex justify-content-center">
                                                <p-overlayPanel #op1>

                                                    <ng-template pTemplate="content">
                                                        <h4>Select User</h4>
                                                        <p-dropdown [options]="drivers" [showClear]="true"
                                                            [(ngModel)]="assignedDriver" optionLabel="fullName"
                                                            placeholder="Select User"
                                                            (onChange)="sortByAssignedUser($event,op1,'OPEN')"></p-dropdown>
                                                    </ng-template>


                                                </p-overlayPanel>
                                                <i class="pi pi-filter" (click)="op1.toggle($event)"></i>
                                            </div> -->
                                        </div>

                                    </th>
                                    <th pSortableColumn="createdBy"><div style="display: flex;flex-direction: row;">
                                        Created By
                                        <div *ngIf="hideButtonsIfGuard == false" class="flex justify-content-center">
                                            <p-overlayPanel #op2>

                                                <ng-template pTemplate="content">
                                                    <h4>Select User</h4>
                                                    <p-dropdown [options]="allUsers" [showClear]="true"
                                                    [(ngModel)]="createdUserSpotterQueue" optionLabel="fullName"
                                                        placeholder="Select User"
                                                        (onChange)="sortByCreatedUser($event,op2,'QUEUE','BUCKET_SPOTTER')"></p-dropdown>
                                                </ng-template>


                                            </p-overlayPanel>
                                            <i class="pi pi-filter" (click)="op2.toggle($event)"></i>
                                        </div>
                                    </div></th>
                                    <th pSortableColumn="weather">Weather</th>
                                    <th pSortableColumn="sequenceAsn" *ngIf="isSupervisorOrClient">Sequence ASN</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-job let-columns="columns" let-index="rowIndex">
                                <tr [pReorderableRow]="index">
                                    <td *ngIf="filterByClient">
                                        <span class="pi pi-bars" pReorderableRowHandle></span> 
                                    </td>
                                    <td>
                                        <button
                                            *ngIf="(job.status !== 'COMPLETED' && job.status !== 'IN_TRANSIT') && userType !== 'ROLE_CLIENT'"
                                            pButton pRipple icon="pi pi-pencil" pTooltip="Edit"
                                            class="p-button-rounded p-button-help mr-2"
                                            (click)="routeToEdit(job.jobId,'QUEUE')"></button>
                                        <button
                                            *ngIf="job.status == 'OPEN' || job.status == 'QUEUE' && userType !== 'ROLE_CLIENT' && hideButtonsIfGuard === false && hideButtonsIfSpotter === false && hideButtonsIfDriver === false"
                                            pButton pRipple icon="pi pi-trash" pTooltip="Delete Job"
                                            class="p-button-rounded p-button-danger mr-2"
                                            (click)="deleteJob(job.jobId)"></button>
                                        <button *ngIf="job.status == 'OPEN' && userType !== 'ROLE_CLIENT'" pButton
                                            pRipple icon="pi pi-car" pTooltip="Confirm Pickup"
                                            class="p-button-rounded p-button-success mr-2"
                                            (click)="openStatusDialog(job,'Confirm Pickup')"></button>
                                        <button *ngIf="job.status == 'IN_TRANSIT' && userType !== 'ROLE_CLIENT'" pButton
                                            pRipple icon="pi pi-car" pTooltip="Confirm Drop"
                                            class="p-button-rounded p-button-warning mr-2"
                                            (click)="openStatusDialog(job,'Confirm Drop')"></button>
                                        <button *ngIf="job.bols && job.bols.length > 0" pButton pRipple
                                            icon="pi pi-images" pTooltip="View BOL images"
                                            class="p-button-rounded p-button-secondary mr-2"
                                            (click)="viewBol(job.bols)"></button>
                                        <!-- <button pButton pRipple icon="pi pi-exclamation-circle"
                                            pTooltip="Exception for moves"
                                            class="p-button-rounded p-button-secondary mr-2"
                                            (click)="exceptions(job.jobId)"></button> -->

                                    </td>
                                    <td><span class="p-column-title">Spot Creation Date</span>
                                        {{job.audit?.createdDate}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Spot Pickup Time</span>
                                        {{job?.pickupDateTime ? job?.pickupDateTime : '-'}}
                                        <!-- <span *ngIf="job?.jobCompletionSeconds">(hh:mm:ss)</span> -->
                                    </td>
                                    <td>
                                        <span class="p-column-title">Spot Dropoff Time</span>
                                        {{job?.dropDateTime ? job?.dropDateTime : '-'}}
                                        <!-- <span *ngIf="job?.jobCompletionSeconds">(hh:mm:ss)</span> -->
                                    </td>
                                    <td><span class="p-column-title">Spot Number</span>
                                        {{job.jobNumber}}
                                    </td>
                                    <td class="text-center"><span class="p-column-title">Job Priority</span>
                                        <p-tag *ngIf="job.priority == 'HIGH'" rounded="true" severity="danger"
                                            value="High"></p-tag>
                                        <p-tag *ngIf="job.priority == 'MEDIUM'" rounded="true" severity="primary"
                                            value="Medium"></p-tag>
                                        <p-tag *ngIf="job.priority == 'LOW'" rounded="true" severity="success"
                                            value="Low"></p-tag>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Pickup Location</span>
                                        {{job.pickupLocation?.locationName}}, {{job.pickupLocation?.state}} - 
                                        <br/>
                                        {{job.pickupSpot?.spotName}}
                                    </td>
                                    
                                    <td>
                                        <span class="p-column-title">Drop Location</span>
                                        {{job.dropLocation?.locationName}}, {{job.dropLocation?.state}} - 
                                        <br/>
                                        {{job.dropSpot?.spotName}}
                                    </td>
                                    
                                    <td style="max-width: 100px; overflow: hidden;word-wrap: break-word;">
                                        <span class="p-column-title">Notes</span>
                                        <div *ngIf="job.description">
                                            <b>{{job.descriptionRole}}</b> {{job.description}}
                                        </div>
                                        <div *ngIf="job.pickupNotes">
                                            <b>{{job.pickupRole}}</b> {{job.pickupNotes}}
                                        </div>
                                        <div *ngIf="job.dropNotes">
                                            <b>{{job.dropRole}}</b> {{job.dropNotes}}
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Trailer/Container</span>
                                        {{job.fleet?.unitNumber}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Status</span>
                                        <p-tag *ngIf="job.status == 'OPEN'" rounded="true" severity="warning"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'IN_TRANSIT'" rounded="true" severity="primary"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'COMPLETED'" rounded="true" severity="success"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'EXCEPTION'" rounded="true" severity="success"
                                            [value]="job.status"></p-tag>
                                    </td>
                                    <!-- <td>
                                        <span class="p-column-title">Exceptions</span>
                                        {{ job.trailerException }}
                                    </td> -->
                                    <td>
                                        <span class="p-column-title">Assigned To</span>
                                        {{job.assignedTo?.firstName}} {{job.assignedTo?.lastName}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Created By</span>
                                        {{job.audit.createdBy?.firstName}} {{job.audit.createdBy?.lastName}}
                                    </td>
                                    <td>
                                        <div *ngIf="job.climate">
                                            <!-- <span class="p-column-title">Weather</span> -->
                                            {{job.climate}}
                                            <div>
                                                Temp:{{job.temperature}}&nbsp;F
                                            </div>

                                        </div>
                                    </td>
                                    <td *ngIf="isSupervisorOrClient">
                                        <span class="p-column-title">Sequence ASN</span>
                                        {{ job.sequenceAsn }}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="16">No spots found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <p-paginator [rows]="50" [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            [totalRecords]="totalRecordsOpen" (onPageChange)="paginateSpotterQueue($event,'QUEUE')"
                            #paginator6></p-paginator>
                    </p-accordionTab>
                    <p-accordionTab header="Open - {{ totalRecordsOpen }}">
                        <p-table #dt1 [value]="jobsOpen" [loading]="loading"
                            styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true"
                            [columns]="cols" [rows]="10"
                            [globalFilterFields]="['pickupLocation','pickupSpot','dropLocation','dropSpot','jobNumber']"
                            [rowHover]="true" dataKey="id" [responsiveLayout]="'scroll'">

                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 70px;"></th>
                                    <th pSortableColumn="createdDate">
                                        <div style="display: flex;flex-direction: row;">
                                            Spot Creation Date
                                            <div class="flex justify-content-center">
                                                <p-overlayPanel #op>

                                                    <ng-template pTemplate="content" style="height: 2000px;">
                                                        <h4>Sort Created Date</h4>
                                                        <p-dropdown placeholder="Order By" [showClear]="true" [(ngModel)]="selectedSortDate"
                                                            [options]="createdDateSort" optionLabel="name"
                                                            (onChange)="sortCreationDate($event,op,'OPEN')"
                                                            appendTo="body">
                                                        </p-dropdown>
                                                    </ng-template>


                                                </p-overlayPanel>
                                                <i class="pi pi-filter" (click)="op.toggle($event)"></i>
                                            </div>
                                        </div>

                                    </th>
                                    <th pSortableColumn="jobPickupTime">Spot Pickup Time</th>
                                    <th pSortableColumn="jobDropoffTime">Spot Dropoff Time</th>
                                    <th pSortableColumn="jobNumber">
                                        Spot Number
                                        <!-- <p-columnFilter type="text" field="jobNumber" display="menu"></p-columnFilter> -->
                                    </th>
                                    <th pSortableColumn="jobPriority">
                                        Spot Priority
                                        <!-- <i class="pi pi-search" style="font-size: 1.5rem" (click)="demo($event)"></i> -->
                                    </th>
                                    <th pSortableColumn="pickupLocation">Pickup Location</th>
                                    <!-- <th pSortableColumn="pickupSpot">Pickup Parking Spot</th> -->
                                    <th pSortableColumn="dropLocation">Drop Location</th>
                                    <th pSortableColumn="description" style="width: 160px;">Notes</th>
                                    <th pSortableColumn="trailerTruck">Trailer/Container</th>
                                    <th pSortableColumn="status">Status</th>
                                    <!-- <th pSortableColumn="exception">Exceptions</th> -->
                                    <th pSortableColumn="assignedTo">
                                        <div style="display: flex;flex-direction: row;">
                                            Assigned To
                                            <div *ngIf="hideButtonsIfGuard == false" class="flex justify-content-center">
                                                <p-overlayPanel #op1>

                                                    <ng-template pTemplate="content">
                                                        <h4>Select User</h4>
                                                        <p-dropdown [options]="drivers" [showClear]="true"
                                                            [(ngModel)]="assignedDriver" optionLabel="fullName"
                                                            placeholder="Select User"
                                                            (onChange)="sortByAssignedUser($event,op1,'OPEN')"></p-dropdown>
                                                    </ng-template>


                                                </p-overlayPanel>
                                                <i class="pi pi-filter" (click)="op1.toggle($event)"></i>
                                            </div>
                                        </div>

                                    </th>
                                    <th pSortableColumn="createdBy"><div style="display: flex;flex-direction: row;">
                                        Created By
                                        <div *ngIf="hideButtonsIfGuard == false" class="flex justify-content-center">
                                            <p-overlayPanel #op2>

                                                <ng-template pTemplate="content">
                                                    <h4>Select User</h4>
                                                    <p-dropdown [options]="allUsers" [showClear]="true"
                                                    [(ngModel)]="createdUserOpen" optionLabel="fullName"
                                                        placeholder="Select User"
                                                        (onChange)="sortByCreatedUser($event,op2,'OPEN')"></p-dropdown>
                                                </ng-template>


                                            </p-overlayPanel>
                                            <i class="pi pi-filter" (click)="op2.toggle($event)"></i>
                                        </div>
                                    </div></th>
                                    <th pSortableColumn="weather">Weather</th>
                                    <th pSortableColumn="sequenceAsn" *ngIf="isSupervisorOrClient">Sequence ASN</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-job>
                                <tr>
                                    <td>
                                        <button
                                            *ngIf="(job.status !== 'COMPLETED' && job.status !== 'IN_TRANSIT') && userType !== 'ROLE_CLIENT'"
                                            pButton pRipple icon="pi pi-pencil" pTooltip="Edit"
                                            class="p-button-rounded p-button-help mr-2"
                                            (click)="routeToEdit(job.jobId,'OPEN')"></button>
                                        <button
                                            *ngIf="job.status == 'OPEN' && userType !== 'ROLE_CLIENT' && hideButtonsIfGuard === false && hideButtonsIfSpotter === false && hideButtonsIfDriver === false"
                                            pButton pRipple icon="pi pi-trash" pTooltip="Delete Job"
                                            class="p-button-rounded p-button-danger mr-2"
                                            (click)="deleteJob(job.jobId)"></button>
                                        <button *ngIf="job.status == 'OPEN' && userType !== 'ROLE_CLIENT'" pButton
                                            pRipple icon="pi pi-car" pTooltip="Confirm Pickup"
                                            class="p-button-rounded p-button-success mr-2"
                                            (click)="openStatusDialog(job,'Confirm Pickup')"></button>
                                        <button *ngIf="job.status == 'IN_TRANSIT' && userType !== 'ROLE_CLIENT'" pButton
                                            pRipple icon="pi pi-car" pTooltip="Confirm Drop"
                                            class="p-button-rounded p-button-warning mr-2"
                                            (click)="openStatusDialog(job,'Confirm Drop')"></button>
                                        <button *ngIf="job.bols && job.bols.length > 0" pButton pRipple
                                            icon="pi pi-images" pTooltip="View BOL images"
                                            class="p-button-rounded p-button-secondary mr-2"
                                            (click)="viewBol(job.bols)"></button>
                                        <!-- <button pButton pRipple icon="pi pi-exclamation-circle"
                                            pTooltip="Exception for moves"
                                            class="p-button-rounded p-button-secondary mr-2"
                                            (click)="exceptions(job.jobId)"></button> -->

                                    </td>
                                    <td><span class="p-column-title">Spot Creation Date</span>
                                        {{job.audit?.createdDate}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Spot Pickup Time</span>
                                        {{job?.pickupDateTime ? job?.pickupDateTime : '-'}}
                                        <!-- <span *ngIf="job?.jobCompletionSeconds">(hh:mm:ss)</span> -->
                                    </td>
                                    <td>
                                        <span class="p-column-title">Spot Dropoff Time</span>
                                        {{job?.dropDateTime ? job?.dropDateTime : '-'}}
                                        <!-- <span *ngIf="job?.jobCompletionSeconds">(hh:mm:ss)</span> -->
                                    </td>
                                    <td><span class="p-column-title">Spot Number</span>
                                        {{job.jobNumber}}
                                    </td>
                                    <td class="text-center"><span class="p-column-title">Job Priority</span>
                                        <p-tag *ngIf="job.priority == 'HIGH'" rounded="true" severity="danger"
                                            value="High"></p-tag>
                                        <p-tag *ngIf="job.priority == 'MEDIUM'" rounded="true" severity="primary"
                                            value="Medium"></p-tag>
                                        <p-tag *ngIf="job.priority == 'LOW'" rounded="true" severity="success"
                                            value="Low"></p-tag>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Pickup Location</span>
                                        {{job.pickupLocation?.locationName}}, {{job.pickupLocation?.state}} - 
                                        <br/>
                                        {{job.pickupSpot?.spotName}}
                                    </td>
                                    
                                    <td>
                                        <span class="p-column-title">Drop Location</span>
                                        {{job.dropLocation?.locationName}}, {{job.dropLocation?.state}} - 
                                        <br/>
                                        {{job.dropSpot?.spotName}}
                                    </td>
                                    
                                    <td style="max-width: 100px; overflow: hidden;word-wrap: break-word;">
                                        <span class="p-column-title">Notes</span>
                                        <div *ngIf="job.description">
                                            <b>{{job.descriptionRole}}</b> {{job.description}}
                                        </div>
                                        <div *ngIf="job.pickupNotes">
                                            <b>{{job.pickupRole}}</b> {{job.pickupNotes}}
                                        </div>
                                        <div *ngIf="job.dropNotes">
                                            <b>{{job.dropRole}}</b> {{job.dropNotes}}
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Trailer/Container</span>
                                        {{job.fleet?.unitNumber}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Status</span>
                                        <p-tag *ngIf="job.status == 'OPEN'" rounded="true" severity="warning"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'IN_TRANSIT'" rounded="true" severity="primary"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'COMPLETED'" rounded="true" severity="success"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'EXCEPTION'" rounded="true" severity="success"
                                            [value]="job.status"></p-tag>
                                    </td>
                                    <!-- <td>
                                        <span class="p-column-title">Exceptions</span>
                                        {{ job.trailerException }}
                                    </td> -->
                                    <td>
                                        <span class="p-column-title">Assigned To</span>
                                        {{job.assignedTo?.firstName}} {{job.assignedTo?.lastName}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Created By</span>
                                        {{job.audit.createdBy?.firstName}} {{job.audit.createdBy?.lastName}}
                                    </td>
                                    <td>
                                        <div *ngIf="job.climate">
                                            <!-- <span class="p-column-title">Weather</span> -->
                                            {{job.climate}}
                                            <div>
                                                Temp:{{job.temperature}}&nbsp;F
                                            </div>

                                        </div>
                                    </td>
                                    <td *ngIf="isSupervisorOrClient">
                                        <span class="p-column-title">Sequence ASN</span>
                                        {{ job.sequenceAsn }}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="15">No spots found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <p-paginator [rows]="50" [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            [totalRecords]="totalRecordsOpen" (onPageChange)="paginate($event,'OPEN')"
                            #paginator1></p-paginator>
                    </p-accordionTab>
                    <p-accordionTab header="In transit - {{ totalRecordsIntransit }}">
                        <p-table #dt2 [value]="jobsIntransit" [loading]="loadingIntransit"
                            styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true"
                            [columns]="cols" [rows]="10"
                            [globalFilterFields]="['pickupLocation','pickupSpot','dropLocation','dropSpot','jobNumber']"
                            [rowHover]="true" dataKey="id" [responsiveLayout]="'scroll'">

                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 70px;"></th>
                                    <th pSortableColumn="createdDate">
                                        <div style="display: flex;flex-direction: row;">
                                            Spot Creation Date
                                            <div class="flex justify-content-center">
                                                <p-overlayPanel #op>

                                                    <ng-template pTemplate="content" style="height: 2000px;">
                                                        <h4>Sort Created Date</h4>
                                                        <p-dropdown placeholder="Order By" [showClear]="true" [(ngModel)]="selectedSortDateIntransit"
                                                            [options]="createdDateSort" optionLabel="name"
                                                            (onChange)="sortCreationDate($event,op,'IN_TRANSIT')"
                                                            appendTo="body">
                                                        </p-dropdown>
                                                    </ng-template>


                                                </p-overlayPanel>
                                                <i class="pi pi-filter" (click)="op.toggle($event)"></i>
                                            </div>
                                        </div>

                                    </th>
                                    <th pSortableColumn="jobPickupTime">Spot Pickup Time</th>
                                    <th pSortableColumn="jobDropoffTime">Spot Dropoff Time</th>
                                    <th pSortableColumn="jobNumber">
                                        Spot Number
                                        <!-- <p-columnFilter type="text" field="jobNumber" display="menu"></p-columnFilter> -->
                                    </th>
                                    <th pSortableColumn="jobPriority">
                                        Spot Priority
                                        <!-- <i class="pi pi-search" style="font-size: 1.5rem" (click)="demo($event)"></i> -->
                                    </th>
                                    <th pSortableColumn="pickupLocation">Pickup Location</th>
                                    <th pSortableColumn="dropLocation">Drop Location</th>
                                    <th pSortableColumn="description" style="width: 160px;">Notes</th>
                                    <th pSortableColumn="trailerTruck">Trailer/Container</th>
                                    <th pSortableColumn="status">Status</th>
                                    <!-- <th pSortableColumn="exception">Exceptions</th> -->
                                    <th pSortableColumn="assignedTo">
                                        <div style="display: flex;flex-direction: row;">
                                            Assigned To
                                            <div *ngIf="hideButtonsIfGuard == false" class="flex justify-content-center">
                                                <p-overlayPanel #op1>

                                                    <ng-template pTemplate="content">
                                                        <h4>Select User</h4>
                                                        <p-dropdown [options]="drivers" [showClear]="true"
                                                            [(ngModel)]="assignedDriverIntransit" optionLabel="fullName"
                                                            placeholder="Select User"
                                                            (onChange)="sortByAssignedUser($event,op1,'IN_TRANSIT')"></p-dropdown>
                                                    </ng-template>


                                                </p-overlayPanel>
                                                <i class="pi pi-filter" (click)="op1.toggle($event)"></i>
                                            </div>
                                        </div>

                                    </th>
                                    <th pSortableColumn="createdBy"><div style="display: flex;flex-direction: row;">
                                        Created By
                                        <div *ngIf="hideButtonsIfGuard == false" class="flex justify-content-center">
                                            <p-overlayPanel #op2>

                                                <ng-template pTemplate="content">
                                                    <h4>Select User</h4>
                                                    <p-dropdown [options]="allUsers" [showClear]="true"
                                                    [(ngModel)]="createdUserIntransist"  optionLabel="fullName"
                                                        placeholder="Select User"
                                                        (onChange)="sortByCreatedUser($event,op2,'IN_TRANSIT')"></p-dropdown>
                                                </ng-template>


                                            </p-overlayPanel>
                                            <i class="pi pi-filter" (click)="op2.toggle($event)"></i>
                                        </div>
                                    </div></th>
                                    <th pSortableColumn="weather">Weather</th>
                                    <th pSortableColumn="sequenceAsn" *ngIf="isSupervisorOrClient">Sequence ASN</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-job>
                                <tr>
                                    <td>
                                        <button
                                            *ngIf="userType !== 'ROLE_CLIENT' && (job.status !== 'COMPLETED' && job.status !== 'IN_TRANSIT')"
                                            pButton pRipple icon="pi pi-pencil" pTooltip="Edit"
                                            class="p-button-rounded p-button-help mr-2"
                                            (click)="routeToEdit(job.jobId)"></button>
                                        <button
                                            *ngIf="job.status == 'OPEN' && userType !== 'ROLE_CLIENT' && hideButtonsIfGuard === false && hideButtonsIfSpotter === false && hideButtonsIfDriver === false"
                                            pButton pRipple icon="pi pi-trash" pTooltip="Delete Job"
                                            class="p-button-rounded p-button-danger mr-2"
                                            (click)="deleteJob(job.jobId)"></button>
                                        <button *ngIf="job.status == 'OPEN' && userType !== 'ROLE_CLIENT'" pButton
                                            pRipple icon="pi pi-car" pTooltip="Confirm Pickup"
                                            class="p-button-rounded p-button-success mr-2"
                                            (click)="openStatusDialog(job,'Confirm Pickup')"></button>
                                        <button *ngIf="job.status == 'IN_TRANSIT' && userType !== 'ROLE_CLIENT'" pButton
                                            pRipple icon="pi pi-car" pTooltip="Confirm Drop"
                                            class="p-button-rounded p-button-warning mr-2"
                                            (click)="openStatusDialog(job,'Confirm Drop')"></button>
                                        <button *ngIf="job.bols && job.bols.length > 0" pButton pRipple
                                            icon="pi pi-images" pTooltip="View BOL images"
                                            class="p-button-rounded p-button-secondary mr-2"
                                            (click)="viewBol(job.bols)"></button>
                                        <!-- <button pButton pRipple icon="pi pi-exclamation-circle"
                                            pTooltip="Exception for moves"
                                            class="p-button-rounded p-button-secondary mr-2"
                                            (click)="exceptions(job.jobId)"></button> -->

                                    </td>
                                    <td><span class="p-column-title">Spot Creation Date</span>
                                        {{job.audit?.createdDate}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Spot Pickup Time</span>
                                        {{job?.pickupDateTime ? job?.pickupDateTime : '-'}}
                                        <!-- <span *ngIf="job?.jobCompletionSeconds">(hh:mm:ss)</span> -->
                                    </td>
                                    <td>
                                        <span class="p-column-title">Spot Dropoff Time</span>
                                        {{job?.dropDateTime ? job?.dropDateTime : '-'}}
                                        <!-- <span *ngIf="job?.jobCompletionSeconds">(hh:mm:ss)</span> -->
                                    </td>
                                    <td><span class="p-column-title">Spot Number</span>
                                        {{job.jobNumber}}
                                    </td>
                                    <td class="text-center"><span class="p-column-title">Job Priority</span>
                                        <p-tag *ngIf="job.priority == 'HIGH'" rounded="true" severity="danger"
                                            value="High"></p-tag>
                                        <p-tag *ngIf="job.priority == 'MEDIUM'" rounded="true" severity="primary"
                                            value="Medium"></p-tag>
                                        <p-tag *ngIf="job.priority == 'LOW'" rounded="true" severity="success"
                                            value="Low"></p-tag>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Pickup Location</span>
                                        {{job.pickupLocation?.locationName}}, {{job.pickupLocation?.state}} - 
                                        <br/>
                                        {{job.pickupSpot?.spotName}}
                                    </td>
                                    
                                    <td>
                                        <span class="p-column-title">Drop Location</span>
                                        {{job.dropLocation?.locationName}}, {{job.dropLocation?.state}} - 
                                        <br/>
                                        {{job.dropSpot?.spotName}}
                                    </td>
                                    
                                    <td style="max-width: 100px; overflow: hidden;word-wrap: break-word;">
                                        <span class="p-column-title">Notes</span>
                                        <div *ngIf="job.description">
                                            <b>{{job.descriptionRole}}</b> {{job.description}}
                                        </div>
                                        <div *ngIf="job.pickupNotes">
                                            <b>{{job.pickupRole}}</b> {{job.pickupNotes}}
                                        </div>
                                        <div *ngIf="job.dropNotes">
                                            <b>{{job.dropRole}}</b> {{job.dropNotes}}
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Trailer/Container</span>
                                        {{job.fleet?.unitNumber}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Status</span>
                                        <p-tag *ngIf="job.status == 'OPEN'" rounded="true" severity="warning"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'IN_TRANSIT'" rounded="true" severity="primary"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'COMPLETED'" rounded="true" severity="success"
                                            [value]="job.status"></p-tag>
                                            <p-tag *ngIf="job.status == 'EXCEPTION'" rounded="true" severity="success"
                                            [value]="job.status"></p-tag>
                                    </td>
                                    <!-- <td>
                                        <span class="p-column-title">Exceptions</span>
                                        {{ job.trailerException }}
                                    </td> -->
                                    <td>
                                        <span class="p-column-title">Assigned To</span>
                                        {{job.assignedTo?.firstName}} {{job.assignedTo?.lastName}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Created By</span>
                                        {{job.audit.createdBy?.firstName}} {{job.audit.createdBy?.lastName}}
                                    </td>
                                    <td>
                                        <div *ngIf="job.climate">
                                            <!-- <span class="p-column-title">Weather</span> -->
                                            {{job.climate}}
                                            <div>
                                                Temp:{{job.temperature}}&nbsp;F
                                            </div>

                                        </div>
                                    </td>
                                    <td *ngIf="isSupervisorOrClient">
                                        <span class="p-column-title">Sequence ASN</span>
                                        {{ job.sequenceAsn }}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="15">No spots found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <p-paginator [rows]="50" [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            [totalRecords]="totalRecordsIntransit"
                            (onPageChange)="paginateIntransit($event,'IN_TRANSIT')" #paginator2></p-paginator>
                    </p-accordionTab>
                    <p-accordionTab header="Completed">
                        <p-table #dt3 [value]="jobsCompleted" [loading]="loadingComplete"
                            styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true"
                            [columns]="cols" [rows]="10"
                            [globalFilterFields]="['pickupLocation','pickupSpot','dropLocation','dropSpot','jobNumber']"
                            [rowHover]="true" dataKey="id" [responsiveLayout]="'scroll'">

                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 70px;"></th>
                                    <th pSortableColumn="createdDate">
                                        <div style="display: flex;flex-direction: row;">
                                            Spot Creation Date
                                            <div class="flex justify-content-center">
                                                <p-overlayPanel #op>

                                                    <ng-template pTemplate="content" style="height: 2000px;">
                                                        <h4>Sort Created Date</h4>
                                                        <p-dropdown placeholder="Order By" [showClear]="true" [(ngModel)]="selectedSortDateCompleted"
                                                            [options]="createdDateSort" optionLabel="name"
                                                            (onChange)="sortCreationDate($event,op,'COMPLETED')"
                                                            appendTo="body">
                                                        </p-dropdown>
                                                    </ng-template>


                                                </p-overlayPanel>
                                                <i class="pi pi-filter" (click)="op.toggle($event)"></i>
                                            </div>
                                        </div>

                                    </th>
                                    <th pSortableColumn="jobPickupTime">Spot Pickup Time</th>
                                    <th pSortableColumn="jobDropoffTime">Spot Dropoff Time</th>
                                    <th pSortableColumn="jobNumber">
                                        Spot Number
                                        <!-- <p-columnFilter type="text" field="jobNumber" display="menu"></p-columnFilter> -->
                                    </th>
                                    <th pSortableColumn="jobPriority">
                                        Spot Priority
                                        <!-- <i class="pi pi-search" style="font-size: 1.5rem" (click)="demo($event)"></i> -->
                                    </th>
                                    <th pSortableColumn="pickupLocation">Pickup Location</th>
                                    <th pSortableColumn="dropLocation">Drop Location</th>
                                    <th pSortableColumn="description" style="width: 160px;">Notes</th>
                                    <th pSortableColumn="trailerTruck">Trailer/Container</th>
                                    <th pSortableColumn="status">Status</th>
                                    <!-- <th pSortableColumn="exception">Exceptions</th> -->
                                    <th pSortableColumn="assignedTo">
                                        <div style="display: flex;flex-direction: row;">
                                            Assigned To
                                            <div *ngIf="hideButtonsIfGuard == false" class="flex justify-content-center">
                                                <p-overlayPanel #op1>

                                                    <ng-template pTemplate="content">
                                                        <h4>Select User</h4>
                                                        <p-dropdown [options]="drivers" [showClear]="true"
                                                            [(ngModel)]="assignedDriverCompleted" optionLabel="fullName"
                                                            placeholder="Select User"
                                                            (onChange)="sortByAssignedUser($event,op1,'COMPLETED')"></p-dropdown>
                                                    </ng-template>


                                                </p-overlayPanel>
                                                <i class="pi pi-filter" (click)="op1.toggle($event)"></i>
                                            </div>
                                        </div>

                                    </th>
                                    <th pSortableColumn="createdBy"><div style="display: flex;flex-direction: row;">
                                        Created By
                                        <div *ngIf="hideButtonsIfGuard == false" class="flex justify-content-center">
                                            <p-overlayPanel #op2>

                                                <ng-template pTemplate="content">
                                                    <h4>Select User</h4>
                                                    <p-dropdown [options]="allUsers" [showClear]="true"
                                                       [(ngModel)]="createdUserCompleted"  optionLabel="fullName"
                                                        placeholder="Select User"
                                                        (onChange)="sortByCreatedUser($event,op2,'COMPLETED')"></p-dropdown>
                                                </ng-template>

                                               
                                            </p-overlayPanel>
                                            <i class="pi pi-filter" (click)="op2.toggle($event)"></i>
                                            
                                        </div>
                                    </div></th>
                                    <th pSortableColumn="weather">Weather</th>
                                    <th pSortableColumn="sequenceAsn" *ngIf="isSupervisorOrClient">Sequence ASN</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-job>
                                <tr>
                                    <td>
                                        <button
                                            *ngIf="userType !== 'ROLE_CLIENT' && (job.status !== 'COMPLETED' && job.status !== 'IN_TRANSIT')"
                                            pButton pRipple icon="pi pi-pencil" pTooltip="Edit"
                                            class="p-button-rounded p-button-help mr-2"
                                            (click)="routeToEdit(job.jobId)"></button>
                                        <button
                                            *ngIf="job.status == 'OPEN' && userType !== 'ROLE_CLIENT' && hideButtonsIfGuard === false && hideButtonsIfSpotter === false && hideButtonsIfDriver === false"
                                            pButton pRipple icon="pi pi-trash" pTooltip="Delete Job"
                                            class="p-button-rounded p-button-danger mr-2"
                                            (click)="deleteJob(job.jobId)"></button>
                                        <button *ngIf="job.status == 'OPEN' && userType !== 'ROLE_CLIENT'" pButton
                                            pRipple icon="pi pi-car" pTooltip="Confirm Pickup"
                                            class="p-button-rounded p-button-success mr-2"
                                            (click)="openStatusDialog(job,'Confirm Pickup')"></button>
                                        <button *ngIf="job.status == 'IN_TRANSIT' && userType !== 'ROLE_CLIENT'" pButton
                                            pRipple icon="pi pi-car" pTooltip="Confirm Drop"
                                            class="p-button-rounded p-button-warning mr-2"
                                            (click)="openStatusDialog(job,'Confirm Drop')"></button>
                                        <button *ngIf="job.bols && job.bols.length > 0" pButton pRipple
                                            icon="pi pi-images" pTooltip="View BOL images"
                                            class="p-button-rounded p-button-secondary mr-2"
                                            (click)="viewBol(job.bols)"></button>

                                    </td>
                                    <td><span class="p-column-title">Spot Creation Date</span>
                                        {{job.audit?.createdDate}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Spot Pickup Time</span>
                                        {{job?.pickupDateTime ? job?.pickupDateTime : '-'}}
                                        <!-- <span *ngIf="job?.jobCompletionSeconds">(hh:mm:ss)</span> -->
                                    </td>
                                    <td>
                                        <span class="p-column-title">Spot Dropoff Time</span>
                                        {{job?.dropDateTime ? job?.dropDateTime : '-'}}
                                        <!-- <span *ngIf="job?.jobCompletionSeconds">(hh:mm:ss)</span> -->
                                    </td>
                                    <td><span class="p-column-title">Spot Number</span>
                                        {{job.jobNumber}}
                                    </td>
                                    <td class="text-center"><span class="p-column-title">Job Priority</span>
                                        <p-tag *ngIf="job.priority == 'HIGH'" rounded="true" severity="danger"
                                            value="High"></p-tag>
                                        <p-tag *ngIf="job.priority == 'MEDIUM'" rounded="true" severity="primary"
                                            value="Medium"></p-tag>
                                        <p-tag *ngIf="job.priority == 'LOW'" rounded="true" severity="success"
                                            value="Low"></p-tag>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Pickup Location</span>
                                        {{job.pickupLocation?.locationName}}, {{job.pickupLocation?.state}} - 
                                        <br/>
                                        {{job.pickupSpot?.spotName}}
                                    </td>
                                    
                                    <td>
                                        <span class="p-column-title">Drop Location</span>
                                        {{job.dropLocation?.locationName}}, {{job.dropLocation?.state}} - 
                                        <br/>
                                        {{job.dropSpot?.spotName}}
                                    </td>
                                    
                                    <td style="max-width: 100px; overflow: hidden;word-wrap: break-word;">
                                        <span class="p-column-title">Notes</span>
                                        <div *ngIf="job.description">
                                            <b>{{job.descriptionRole}}</b> {{job.description}}
                                        </div>
                                        <div *ngIf="job.pickupNotes">
                                            <b>{{job.pickupRole}}</b> {{job.pickupNotes}}
                                        </div>
                                        <div *ngIf="job.dropNotes">
                                            <b>{{job.dropRole}}</b> {{job.dropNotes}}
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Trailer/Container</span>
                                        {{job.fleet?.unitNumber}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Status</span>
                                        <p-tag *ngIf="job.status == 'OPEN'" rounded="true" severity="warning"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'IN_TRANSIT'" rounded="true" severity="primary"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'COMPLETED'" rounded="true" severity="success"
                                            [value]="job.status"></p-tag>
                                            <p-tag *ngIf="job.status == 'EXCEPTION'" rounded="true" severity="success"
                                            [value]="job.status"></p-tag>
                                    </td>
                                    <!-- <td>
                                        <span class="p-column-title">Exceptions</span>
                                        {{ job.trailerException }}
                                    </td> -->
                                    <td>
                                        <span class="p-column-title">Assigned To</span>
                                        {{job.assignedTo?.firstName}} {{job.assignedTo?.lastName}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Created By</span>
                                        {{job.audit.createdBy?.firstName}} {{job.audit.createdBy?.lastName}}
                                    </td>
                                    <td>
                                        <div *ngIf="job.climate">
                                            <!-- <span class="p-column-title">Weather</span> -->
                                            {{job.climate}}
                                            <div>
                                                Temp:{{job.temperature}}&nbsp;F
                                            </div>

                                        </div>
                                    </td>
                                    <td *ngIf="isSupervisorOrClient">
                                        <span class="p-column-title">Sequence ASN</span>
                                        {{ job.sequenceAsn }}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="15">No spots found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <p-paginator [rows]="50" [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            [totalRecords]="totalRecordsCompleted"
                            (onPageChange)="paginateCompleted($event,'COMPLETED')" #paginator3></p-paginator>
                    </p-accordionTab>
                    <p-accordionTab header="Exceptions - {{ totalRecordsException }}" *ngIf="exceptionActive">
                        <p-table #dt4 [value]="jobsException" [loading]="loadingException"
                            styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true"
                            [columns]="cols" [rows]="10"
                            [globalFilterFields]="['pickupLocation','pickupSpot','dropLocation','dropSpot','jobNumber']"
                            [rowHover]="true" dataKey="id" [responsiveLayout]="'scroll'">

                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 70px;"></th>
                                    <th pSortableColumn="createdDate">
                                        <div style="display: flex;flex-direction: row;">
                                            Spot Creation Date
                                            <div class="flex justify-content-center">
                                                <p-overlayPanel #op>

                                                    <ng-template pTemplate="content" style="height: 2000px;">
                                                        <h4>Sort Created Date</h4>
                                                        <p-dropdown placeholder="Order By" [showClear]="true" [(ngModel)]="selectedSortDateException"
                                                            [options]="createdDateSort" optionLabel="name"
                                                            (onChange)="sortCreationDate($event,op,'EXCEPTION')"
                                                            appendTo="body">
                                                        </p-dropdown>
                                                    </ng-template>


                                                </p-overlayPanel>
                                                <i class="pi pi-filter" (click)="op.toggle($event)"></i>
                                            </div>
                                        </div>

                                    </th>
                                    <th pSortableColumn="jobPickupTime">Spot Pickup Time</th>
                                    <th pSortableColumn="jobDropoffTime">Spot Dropoff Time</th>
                                    <th pSortableColumn="jobNumber">
                                        Spot Number
                                        <!-- <p-columnFilter type="text" field="jobNumber" display="menu"></p-columnFilter> -->
                                    </th>
                                    <th pSortableColumn="jobPriority">
                                        Spot Priority
                                        <!-- <i class="pi pi-search" style="font-size: 1.5rem" (click)="demo($event)"></i> -->
                                    </th>
                                    <th pSortableColumn="pickupLocation">Pickup Location</th>
                                    <th pSortableColumn="dropLocation">Drop Location</th>
                                    <th pSortableColumn="description" style="width: 160px;">Notes</th>
                                    <th pSortableColumn="trailerTruck">Trailer/Container</th>
                                    <th pSortableColumn="status">Status</th>
                                    <th pSortableColumn="exception">Exceptions</th>
                                    <th pSortableColumn="assignedTo">
                                        <div style="display: flex;flex-direction: row;">
                                            Assigned To
                                            <div class="flex justify-content-center">
                                                <p-overlayPanel #op1>

                                                    <ng-template pTemplate="content">
                                                        <h4>Select User</h4>
                                                        <p-dropdown [options]="drivers" [showClear]="true"
                                                            [(ngModel)]="assignedDriverException" optionLabel="fullName"
                                                            placeholder="Select User"
                                                            (onChange)="sortByAssignedUser($event,op1,'EXCEPTION')"></p-dropdown>
                                                    </ng-template>


                                                </p-overlayPanel>
                                                <i class="pi pi-filter" (click)="op1.toggle($event)"></i>
                                            </div>
                                        </div>

                                    </th>
                                    <th pSortableColumn="createdBy">Created By</th>
                                    <th pSortableColumn="weather">Weather</th>
                                    <th pSortableColumn="sequenceAsn" *ngIf="isSupervisorOrClient">Sequence ASN</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-job>
                                <tr>
                                    <td>
                                        
                                        <button *ngIf="job.bols && job.bols.length > 0" pButton pRipple
                                            icon="pi pi-images" pTooltip="View BOL images"
                                            class="p-button-rounded p-button-secondary mr-2"
                                            (click)="viewBol(job.bols)"></button>

                                    </td>
                                    <td><span class="p-column-title">Spot Creation Date</span>
                                        {{job.audit?.createdDate}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Spot Pickup Time</span>
                                        {{job?.pickupDateTime ? job?.pickupDateTime : '-'}}
                                        <!-- <span *ngIf="job?.jobCompletionSeconds">(hh:mm:ss)</span> -->
                                    </td>
                                    <td>
                                        <span class="p-column-title">Spot Dropoff Time</span>
                                        {{job?.dropDateTime ? job?.dropDateTime : '-'}}
                                        <!-- <span *ngIf="job?.jobCompletionSeconds">(hh:mm:ss)</span> -->
                                    </td>
                                    <td><span class="p-column-title">Spot Number</span>
                                        {{job.jobNumber}}
                                    </td>
                                    <td class="text-center"><span class="p-column-title">Job Priority</span>
                                        <p-tag *ngIf="job.priority == 'HIGH'" rounded="true" severity="danger"
                                            value="High"></p-tag>
                                        <p-tag *ngIf="job.priority == 'MEDIUM'" rounded="true" severity="primary"
                                            value="Medium"></p-tag>
                                        <p-tag *ngIf="job.priority == 'LOW'" rounded="true" severity="success"
                                            value="Low"></p-tag>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Pickup Location</span>
                                        {{job.pickupLocation?.locationName}}, {{job.pickupLocation?.state}} - 
                                        <br/>
                                        {{job.pickupSpot?.spotName}}
                                    </td>
                                    
                                    <td>
                                        <span class="p-column-title">Drop Location</span>
                                        {{job.dropLocation?.locationName}}, {{job.dropLocation?.state}} - 
                                        <br/>
                                        {{job.dropSpot?.spotName}}
                                    </td>
                                    
                                    <td style="max-width: 100px; overflow: hidden;word-wrap: break-word;">
                                        <span class="p-column-title">Notes</span>
                                        <div *ngIf="job.description">
                                            <b>{{job.descriptionRole}}</b> {{job.description}}
                                        </div>
                                        <div *ngIf="job.pickupNotes">
                                            <b>{{job.pickupRole}}</b> {{job.pickupNotes}}
                                        </div>
                                        <div *ngIf="job.dropNotes">
                                            <b>{{job.dropRole}}</b> {{job.dropNotes}}
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Trailer/Container</span>
                                        {{job.fleet?.unitNumber}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Status</span>
                                        <p-tag *ngIf="job.status == 'OPEN'" rounded="true" severity="warning"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'IN_TRANSIT'" rounded="true" severity="primary"
                                            [value]="job.status"></p-tag>
                                        <p-tag *ngIf="job.status == 'COMPLETED'" rounded="true" severity="success"
                                            [value]="job.status"></p-tag>
                                            <p-tag *ngIf="job.status == 'EXCEPTION'" rounded="true" severity="danger"
                                            [value]="job.status"></p-tag>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Exceptions</span>
                                        {{ job.trailerException }}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Assigned To</span>
                                        {{job.assignedTo?.firstName}} {{job.assignedTo?.lastName}}
                                    </td>
                                    <td>
                                        <span class="p-column-title">Created By</span>
                                        {{job.audit.createdBy?.firstName}} {{job.audit.createdBy?.lastName}}
                                    </td>
                                    <td>
                                        <div *ngIf="job.climate">
                                            <!-- <span class="p-column-title">Weather</span> -->
                                            {{job.climate}}
                                            <div>
                                                Temp:{{job.temperature}}&nbsp;F
                                            </div>

                                        </div>
                                    </td>
                                    <td *ngIf="isSupervisorOrClient">
                                        <span class="p-column-title">Sequence ASN</span>
                                        {{ job.sequenceAsn }}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="15">No spots found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <p-paginator [rows]="50" [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            [totalRecords]="totalRecordsException"
                            (onPageChange)="paginateExceptions($event,'EXCEPTION')" #paginator4></p-paginator>
                    </p-accordionTab>
                </p-accordion>
            </div>

        </div>

        <!-- <p-dialog [(visible)]="jobStatusDialog" [style]="{width: '450px'}" header="Change Job Status" [modal]="true" class="p-fluid">
            <ng-template pTemplate="content">
                <form [formGroup]="jobStatusForm">
                    <div class="field">
                        <label for="status">Status</label>
                        <p-dropdown placeholder="Select Status" [options]="status" formControlName="status" optionLabel="name" optionValue="code"></p-dropdown>
                        <small class="ng-dirty ng-invalid" *ngIf="jobStatusForm.controls.status.touched &&
                        jobStatusForm.controls.status.invalid">Status is required.</small>
                    </div>
                    <div class="field">
                        <label for="notes">Notes</label>
                        <textarea pInputTextarea autoResize formControlName="notes" rows="3" cols="30"></textarea>
                    </div>
                </form>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hidejobStatusDialog()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" type="button" (click)="changeJobStatus()"></button>
            </ng-template>
        </p-dialog> -->

        <p-dialog [(visible)]="jobStatusDialog" [style]="{width: '450px'}" header="{{modalTitle}}" [modal]="true"
            class="p-fluid">
            <ng-template pTemplate="content">
                <div class="flex flex-column mt-4">
                    <div class="flex justify-content-between">
                        <div class="flex">
                            <i class="pi pi-map-marker mr-2"></i>
                            <span class="font-semibold">{{modalTitle == 'Confirm Pickup' ? 'Pickup' : 'Drop'}}</span>
                        </div>
                        <div class="flex">
                            <span class="font-semibold spots">{{modalTitle == 'Confirm Pickup' ?
                                modalJob?.pickupSpot?.spotName : modalJob?.dropSpot?.spotName}}</span>
                        </div>
                    </div>
                    <div *ngIf="modalTitle == 'Confirm Pickup'" class="flex flex-column mt-1">
                        <p class="mb-1"><b>{{modalJob.pickupLocation?.locationName}}</b></p>
                        <small>{{modalJob.pickupLocation?.street}}, {{modalJob.pickupLocation?.city}},
                            {{modalJob.pickupLocation?.state}}, {{modalJob.pickupLocation?.zip}}</small>
                    </div>
                    <!-- <div *ngIf="modalTitle !== 'Confirm Pickup'" class="flex flex-column mt-1">
                        <p class="mb-1"><b>{{modalJob.dropLocation?.locationName}}</b></p>
                        <small>{{modalJob.dropLocation?.street}}, {{modalJob.dropLocation?.city}}, {{modalJob.dropLocation?.state}}, {{modalJob.dropLocation?.zip}}</small>
                    </div> -->
                </div>
                <hr>
                <form *ngIf="modalTitle !== 'Confirm Pickup'" [formGroup]="jobForm">
                    <div class="field">
                        <label for="locationList">Location</label>
                        <p-dropdown [options]="locationList" formControlName="dropLocationId"
                            placeholder="Select Drop Location" optionLabel="locationName" optionValue="locationId"
                            (onChange)="onSelectLocation($event)"></p-dropdown>
                    </div>

                    <div class="field">
                        <label for="dropSpot">Drop Parking Spot</label>
                        <p-dropdown [options]="dropSpots" formControlName="dropSpotId" placeholder="Select Drop Spot"
                            optionLabel="spotAndStatus" optionValue="spotId"></p-dropdown>
                    </div>
                    <div class="field">
                        <label for="trailerStatus">Trailer/Container Status</label>
                        <p-dropdown [options]="trailerStatus" formControlName="fleetStatus"
                            placeholder="Select Trailer/Container Status" optionLabel="name"
                            optionValue="code"></p-dropdown>
                    </div>
                </form>
                <form [formGroup]="jobStatusForm">
                    <div class="field">
                        <label for="remarks">Drop Notes(If any)</label>
                        <textarea pInputTextarea autoResize formControlName="notes" rows="3" cols="30"></textarea>
                    </div>
                </form>

            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                    (click)="hidejobStatusDialog()"></button>
                <button pButton pRipple label="Confirm" icon="pi pi-check" class="p-button-text"
                    (click)="changeJobStatus()"></button>
            </ng-template>
        </p-dialog>

        <p-dialog [(visible)]="deleteProductDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span *ngIf="product">Are you sure you want to delete <b>{{product.name}}</b>?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No"
                    (click)="deleteProductDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes"
                    (click)="confirmDelete()"></button>
            </ng-template>
        </p-dialog>
    </div>
</div>
<p-dialog [(visible)]="deleteJobDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
    <div class="flex align-items-center justify-content-start">
        <i class="pi pi-exclamation-triangle mr-3 my-4" style="font-size: 2rem"></i>
        <span>Are you sure you want to delete this spot ?</span>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No"
            (click)="deleteJobDialog = false"></button>
        <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDelete()"></button>
    </ng-template>
</p-dialog>
<p-dialog [(visible)]="bolDialog" header="BoL Images" [modal]="true" [style]="{width:'700px',height: '700px'}">

    <ng-template pTemplate="content">
        <!-- <p-image *ngIf="selectedBol && selectedBol[0]" [src]="selectedBol[0].imagePath" alt="Image" width="250"></p-image> -->
        <p-carousel [value]="selectedBol" (onPage)="onPageChange($event)" [numVisible]="1" [numScroll]="1"
            [circular]="false">
            <ng-template let-bol pTemplate="item">
                <div class="border-1 surface-border border-round m-2 text-center py-6 px-3 custom-div">
                    <div>
                        <p-image [src]="bol.imagePath" alt="Image" width="100%" height="400px"></p-image>

                    </div>
                </div>
                <!-- <button pButton pRipple label="Download" class="p-button-success" (click)="downloadImage(bol.imagePath)"></button> -->
                <!-- <a [href]="bol.imagePath" download="downloaded-image.jpg" target="_blank">Download Image</a> -->
            </ng-template>
        </p-carousel>

    </ng-template>
    <ng-template pTemplate="footer">
        <!-- <button pButton pRipple label="Download" class="p-button-success"></button> -->
        <a [href]="selectedImagePath" download="downloaded-image.jpg" target="_blank">Download Image</a>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="exceptionDialog" header="Exception for moves" [modal]="true" (onHide)="cancelExceptionDialog()"
    [style]="{width:'600px',height: '500px'}">

    <ng-template pTemplate="content">
        <div class="">
            <div class="field">
                <label>Select the type of exception</label>
            </div>
            <div class="field">

            </div>

            <div class="field">
                <input pInputText type="text" class="w-full" placeholder="Enter new exception"
                    (blur)="selectException($event.target.value)" [(ngModel)]="exceptionInputText" />
            </div>

        </div>

        <ng-template ngFor let-item [ngForOf]="exceptionLists" class="exception-flex-style">
            <button pButton label="{{item.name}}" class="p-button exception-flex-style-child"
                [ngClass]="item.name == selectedException ? 'active':''"
                (click)="selectException(item.name,1)"></button>
            <!-- <p-radioButton name="group1"  value="{{item.name}}" [(ngModel)]="selectedButton" (click)="selectException(item.name)" class="custom-radio exception-flex-style-child">
                <label>Option 1</label>
            </p-radioButton> -->

        </ng-template>

    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple class="p-button mrgr-10" label="Send" (click)="sendException()"></button>
        <button pButton label="Cancel" class="p-button-text" (click)="cancelExceptionDialog()"></button>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="isExportClicked" header="Export Date Range" [modal]="true" [style]="{width:'600px'}" (onHide)="clearExportPopup()">

    <ng-template pTemplate="content">
        <span class="block mt-2 md:mt-0 p-input-icon-left">
            <span class="mr-2 font-medium">From Date : <input pInputText type="date"
                    [(ngModel)]="fromExportDate" name="fromExportDate"
                    placeholder="Select Date" /></span>
            <span class="mx-2 font-medium">To Date : <input pInputText type="date" [(ngModel)]="toExportDate"
                     name="toExportDate" placeholder="Select Date" /></span>
        </span>
        <span class="error" *ngIf="exportDateValid">*Please enter both from and to date.</span>
        <span class="error" *ngIf="exportRangeValid">Please enter a valid date range.</span>
    </ng-template>
    <ng-template pTemplate="footer">
    <button pButton pRipple icon="pi pi-times" class="p-button-text" label="Cancel"
        (click)="clearExportPopup()"></button>
    <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Ok"
        (click)="exportExcel()"></button>
    </ng-template>
</p-dialog>

<!-- <button *ngIf="i%2==0" pButton pRipple class="p-button-success p-button-sm" (click)="openNew('Confirm Pickup')">Pick Up</button>
                                    <button *ngIf="i%2!==0" pButton pRipple class="p-button-warning p-button-sm" (click)="openNew('Confirm Drop')">Drop Now</button> -->