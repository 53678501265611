import { Component, OnInit } from '@angular/core';
import { Product } from '../../api/product';
import { ProductService } from '../../service/productservice';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { concatMap, map, Subscription } from 'rxjs';
import { ManageFleetsService } from '../manage-fleets/manage-fleets.service';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { ManageClientsService } from '../manage-clients/manage-clients.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { ManageLocationsService } from '../manage-locations/manage-locations.service';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { ManageEntryExitService } from './manage-entry-exit.service';
import { TokenService } from 'src/app/security/token.service';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import * as moment from 'moment';
import { ErrorService } from 'src/app/error-handling/error.service';
import { ManageSpotsService } from '../manage-spots/manage-spots.service';
import { ManageUsersService } from '../manage-users/manage-users.service';
import { ManageTrailerTrackingServiceService } from '../manage-trailer-tracking/manage-trailer-tracking-service.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AddFleetService } from '../add-fleet/add-fleet.service';

@Component({
  selector: 'app-manage-entry-exit',
  templateUrl: './manage-entry-exit.component.html',
  providers: [MessageService, ConfirmationService, DatePipe],
  styleUrls: ['../../../assets/demo/badges.scss','./manage-entry-exit.component.scss']
})
export class ManageEntryExitComponent implements OnInit {

  breadcrumbItems: MenuItem[];

  productDialog: boolean;

  deleteProductDialog: boolean = false;

    deleteProductsDialog: boolean = false;

    entryExitList = [
      // {
      //     location:"Dynacraft, 123 W. Main St.Lousiville",
      //     trailer: '12NZ4566',
      //     carrier: 'FedEx',
      //     notes: 'Pull out empty trailer first',
      //     entryTime: '1/1/2022, 10:42PM',
      //     exitTime: '1/1/2022, 11:30PM',
      //     reportBy: 'John Doe',
      //     typeOfEntry:"Entry",
      //     typeOfTrailerTruck:"Truck"
      // },
      // {
      //   location:"Dynacraft, 123 W. Main St.Lousiville",
      //   trailer: '12NZ4566',
      //   carrier: 'FedEx',
      //   notes: 'Pull out empty trailer first',
      //   entryTime: '1/1/2022, 10:42PM',
      //   exitTime: '1/1/2022, 11:30PM',
      //   reportBy: 'John Doe',
      //   typeOfEntry:"Exit",
      //   typeOfTrailerTruck:"Trailer"
      // },
      // {
      //   location:"Dynacraft, 123 W. Main St.Lousiville",
      //   trailer: '12NZ4566',
      //   carrier: 'FedEx',
      //   notes: 'Pull out empty trailer first',
      //   entryTime: '1/1/2022, 10:42PM',
      //   exitTime: '1/1/2022, 11:30PM',
      //   reportBy: 'John Doe',
      //   typeOfEntry:"Entry",
      //   typeOfTrailerTruck:"Truck"
      // },
      // {
      //   location:"Dynacraft, 123 W. Main St.Lousiville",
      //   trailer: '12NZ4566',
      //   carrier: 'FedEx',
      //   notes: 'Pull out empty trailer first',
      //   entryTime: '1/1/2022, 10:42PM',
      //   exitTime: '1/1/2022, 11:30PM',
      //   reportBy: 'John Doe',
      //   typeOfEntry:"Exit",
      //   typeOfTrailerTruck:"Trailer"
      // }
  ];

    product: Product;

    selectedProducts: Product[];

    submitted: boolean;

    cols: any[];

    statuses: any[];

 

  rowsPerPageOptions = [5, 10, 20];
  modalNotes: any;
  clients: { name: string; value: string; }[];
  entryExitDialog: boolean;
  locationId: any;
  entryExitForm: FormGroup;
  fleetList: any[];
  totalRecords: any;
  clientId: any;
  loading: boolean;
  subscription = new Subscription();
  query : IpagedQuery;
  fleetQuery: { isActive: boolean; size: number; page: number; };
  clientList = [];
  clientsLocationsList = [];
  clientsSpotsList = [];
  type: { name: string; code: string; }[];
  maximumSizequery : IpagedQuery;
  activeInactivequery : IpagedQuery;
  filterByClient: any;
  userRoles: any;
  userType: any;
  accessToken: string;
  deleteEntryExitDialog: boolean;
  entryExitId: any;
  entryExitClientId: any;
  items: MenuItem[];
  hideButtonIfGuard:boolean = false;
  filterFleetId:any;
  selectedFleetId:string;
  selectedDriverId:string;
  selectedCarrierId:string;
  selectedSupplierId:string;
  isTrailerClicked:boolean = false;  
  notAdminIT: boolean = false;
  selectedFile: File | null = null;
  drivers = [];
  supplierList: any[] = [];
  carrierList: string[] = [];
  updatedByList = [];
  loggedInUserId: any;
  trailerTrackingVisible: boolean = false;
  newTrailer: boolean = false;
  entrySubmitted: boolean = false;
  newCarrier: boolean = false;
  newSupplier: boolean = false;
  fileText = "No image uploaded";
  loggedInClient: any;
  loadStatusList: any[] = [{
    loadName: "Empty"
  },
  {
    loadName: "Loaded"
  }];
  bolDialog: boolean = false;
  selectedBol: string = "";
  selectedBolType: string = "";
  isNewTrailer: boolean = false;
  isExportClicked: boolean = false;
  exportDateValid: boolean = false;
  fromExportDate;
  toExportDate;
  exportRangeValid: boolean = false;

    constructor(private manageFleetsService:ManageFleetsService,
      private loader:AppLoaderService,
      private fb : FormBuilder, 
      private manageClientsService : ManageClientsService,
      private manageLocationsService : ManageLocationsService, 
      private alertService : AppAlertService,
      private manageEntryExitService:ManageEntryExitService,
      private tokenService:TokenService,
      private errorService:ErrorService,
      private manageSpotService: ManageSpotsService,
      private manageUsersService: ManageUsersService,
      private trailerTrackingService: ManageTrailerTrackingServiceService,
      private datePipe: DatePipe,
      private router:Router,
      private addFleetService: AddFleetService,
    ) {
                  this.entryExitForm = this.fb.group({
                    clientId: ['',Validators.required],
                    locationId:['',Validators.required],
                    fleetId: ['',Validators.required],
                    trailer:[''],
                    notes: [''],
                    type: ['',Validators.required],
                    spotId: ['', Validators.required],
                    proNumber: [''],
                    driver: ['', Validators.required],
                    driverName:[''],
                    billOfLandingType: [''],
                    billOfLandingImage: [{}],
                    carrier: ['', Validators.required],
                    carrierName:[''],
                    supplierName:[''],
                    supplier: ['', Validators.required],
                    sub: ['', Validators.required],
                    dateOfPickup: ['', Validators.required],
                    loadStatus: ['', Validators.required],
                    dueOfPlant: [''],
                    sequenceNumber: ['', Validators.required],
                    dateOfArrival: ['', Validators.required],
                    tractorNumber: ['', Validators.required],
                    userName: [''],
                    fleetCarrier: [''],
                    newCarrier: [''],
                    newSupplier: [''],
                    newFleet:['']
                });

                this.items = [
                  {
                      label: 'Excel',
                      icon: 'pi pi-download',
                      command: () => {
                          this.exportExcel();
                      }
                  }
                  // ,
                  // {
                  //     label: 'CSV',
                  //     icon: 'pi pi-download',
                  //     command: () => {
                  //         this.exportCsv();
                  //     }
                  // }
              ];


                }

    ngOnInit() {

        this.userRoles = this.tokenService.getUserRoles();
      if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)){
        this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
      } 
      if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_GUARD))
      {
        this.hideButtonIfGuard = true;
      } 

      if (this.userRoles.some(role => role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)) {
        this.notAdminIT = true;
      }

      if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_GUARD || role === APP_CONSTANTS.USER_ROLES.ROLE_ADMIN || role === APP_CONSTANTS.USER_ROLES.ROLE_IT || role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR))
        {
          this.trailerTrackingVisible = true;
        } 

      this.accessToken = this.tokenService.getAccessToken();
        this.query = {isActive:true,size:10,page:0}
    this.maximumSizequery = {isActive:true,size:10000,page:0};
    this.activeInactivequery = {size:10000,page:0};
        this.viewClients(this.maximumSizequery);
    this.getClientList(this.maximumSizequery);
    this.getEntryExit(this.query);
    this.loggedInUserId = this.tokenService.getUserId();

    this.getCarriers("");
    this.getSuppliers();
    
        this.cols = [
            {field: 'name', header: 'Name'},
            {field: 'type', header: 'Type'},
            {field: 'status', header: 'Status'},
            {field: 'latitude', header: 'Latitude'},
            {field: 'longitude', header: 'Longitude'}
        ];

        this.type = [
          { name: 'Entry', code:'ENTRY'},
          { name: 'Exit', code:'EXIT'},
      ]

      this.statuses = [
        {name: 'Active', value: 'Active'},
        {name: 'Disable', value: 'Disable'}
    ];

    this.clients = [
      {name: 'Dynacraft ', value: 'Pegasus'},
      {name: 'Pegasus  ', value: 'Dynacraft'},
    ];

        this.breadcrumbItems = [];
        this.breadcrumbItems.push({ label: 'Entry / Exit Detail'})
}

getEntryExit(query:IpagedQuery,clientId?:any){
  this.loading = true;
  this.manageEntryExitService.getEntryExitList(query,clientId)
  .pipe(
    map(res=>{
      let entryExitList = [];
      for(let entryExit of res.list){
          let obj = {
              ...entryExit,
              dateOfEntry : moment.utc(entryExit.audit.createdDate).format("MM/DD/YYYY"),
              entryTime : moment.utc(entryExit.audit.createdDate).format("hh:mm A")
            };
            entryExitList.push(obj);
      }
      return { 
        list : entryExitList,
        totalElements : res.totalElements
      }
    })
      
  )
  .subscribe(response=>{
    this.entryExitList = response.list;
    this.totalRecords = response.totalElements;
    this.loading = false;
  },(error) => {
    this.loader.hide();
    this.errorService.handleError(error, true);
  })
}

    getClientLocations(query,clientId) {
      this.loader.show();
      this.subscription.add(
          this.manageLocationsService.viewLocations(query,clientId).subscribe(response=>{
              this.clientsLocationsList = response.list;
              this.loader.hide();
          },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
      )
    }

    getClientSpots(query,clientId, locationId) {
      this.loader.show();
      this.subscription.add(
          this.manageSpotService.viewDropdownSpots(query,clientId, locationId).subscribe(response=>{
              this.clientsSpotsList = response.list;
              this.loader.hide();
          },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
      )
    }

    viewClients(query:IpagedQuery){
      this.loading = true;
      this.manageClientsService.viewClients(query).subscribe(response=>{
        this.clients = response.list;
        this.totalRecords = response.totalElements;
        this.loading = false;
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    })
  }

  getClientList(query) {
    this.loader.show()
    this.subscription.add(
      this.manageClientsService.viewClients(query).subscribe(response=>{
        this.clientList = response.list;
        if(this.notAdminIT){
          this.entryExitForm.patchValue({
            clientId: this.clientList[0].clientId
          });
          this.getClientLocations(this.maximumSizequery, this.clientList[0].clientId);
          this.clientId = this.clientList[0].clientId;
          this.loggedInClient = this.clientId;
          this.isTrailerClicked = false;
        }
        this.viewUsers(1);       
        this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    })
    )
  }



    getFleetList(event?: any) {

      if(this.entryExitForm.controls.clientId.invalid)
      {
        this.isTrailerClicked = true;
      }
      else
      {
        
        console.log("event",event.query);
        this.loading = true;
        this.subscription.add(
          this.manageFleetsService.viewFleets(this.activeInactivequery,this.clientId,event.query)
          .pipe(
            map(res=>{
              let fleets = [];
              for(let fleet of res.list){
                let obj = {
                  ...fleet,
                  plateNumber_unitNumber : fleet.plateNumber+"-"+`(${fleet.unitNumber})`,
                  fleetAndHotTrailer : this.checkIfHotTrailer(fleet)
                };
                fleets.push(obj);
              }
              return { 
                list : fleets,
                totalElements : res.totalElements
              }
            })
          )
          .subscribe(response=>{
            this.fleetList = response.list;
            this.totalRecords = response.totalElements;
            this.loading = false;
          },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
        ) 


      }
      
    }

    openEntryExitDialog(locationId){
      this.entryExitDialog = true;
      this.locationId = locationId;
      this.viewUsers();
      if(this.notAdminIT){
        this.entryExitForm.patchValue({
          clientId: this.clientList[0].clientId
        });
      }
    }

    openNew() {
        this.product = {};
        this.submitted = false;
        this.productDialog = true;
    }

    deleteSelectedProducts() {
        this.deleteProductsDialog = true;
    }

    editProduct(product: Product) {
        this.product = {...product};
        this.productDialog = true;
    }

    viewNotes(notes) {
        this.deleteProductDialog = true;
        this.modalNotes = notes;
    }

    hideDialog() {
      this.entryExitDialog = false;
      this.deleteProductDialog = false;
      this.entrySubmitted = false;
      this.entryExitForm.reset();
      this.newCarrier = false;
      this.newTrailer = false;
      this.newSupplier = false;
      this.locationId = null;
      if(this.loggedInClient != null || this.loggedInClient != ""){
        this.clientId = this.loggedInClient;
      }
      
      this.selectedFile = null;
      this.fileText = "No image uploaded";
    }

    saveProduct() {
        this.submitted = true;
    }
   
    // createId(): string {
    //     let id = '';
    //     const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    //     for (let i = 0; i < 5; i++) {
    //         id += chars.charAt(Math.floor(Math.random() * chars.length));
    //     }
    //     return id;
    // }
    onClientSelect(event){
      this.getClientLocations(this.maximumSizequery,event.value);
      this.clientId = event.value;
      this.viewUsers();
      // this.getFleetList(this.activeInactivequery,event.value);
      
      this.isTrailerClicked = false;
   }


   forLocationId(event){
      this.locationId = event.value;
      this.entryExitForm.patchValue({
        spotId: null
      });
      this.getClientSpots(this.maximumSizequery, this.clientId, this.locationId);
   }


    createEntryExit(){
      this.entrySubmitted = true;
      console.log(this.entryExitForm);
      
      if(this.entryExitForm.invalid){
          this.entryExitForm.markAllAsTouched();
      }else{

        // if(this.newTrailer){
        //     this.saveTrailer();
        // }else{
          this.loader.show();
          this.entryExitForm.patchValue({
            fleetId:this.selectedFleetId,
            supplier: this.selectedSupplierId,
            // driver: this.selectedDriverId
          });
          
          
          const formData = new FormData();
          if(this.selectedFile != null && this.selectedFile != undefined){
            formData.append('billOfLandingImage', this.selectedFile);
          }
          
          formData.append('billOfLandingType', this.entryExitForm.value.billOfLandingType);
          formData.append('carrier', this.entryExitForm.value.carrier);
          formData.append('clientId', this.entryExitForm.value.clientId);
          const formattedDate = this.datePipe.transform(this.entryExitForm.value.dateOfArrival, 'yyyy-MM-dd');
          if (formattedDate) {
            formData.append('dateOfArrival', formattedDate);
          }
          const formattedPickupDate = this.datePipe.transform(this.entryExitForm.value.dateOfPickup, 'yyyy-MM-dd');
          if (formattedPickupDate) {
            formData.append('dateOfPickup', formattedPickupDate);
          }
          const formattedDueAtPlant = this.datePipe.transform(this.entryExitForm.value.dueOfPlant, 'yyyy-MM-dd');
          if (formattedDueAtPlant) {
            formData.append('dueOfPlant', formattedDueAtPlant);
          }
          // formData.append('dateOfArrival', this.entryExitForm.value.dateOfArrival);
          // formData.append('dateOfPickup', this.entryExitForm.value.dateOfPickup);
          formData.append('driver', this.entryExitForm.value.driver);
          // formData.append('dueOfPlant', this.entryExitForm.value.dueOfPlant);
          formData.append('fleetId', this.entryExitForm.value.fleetId);
          formData.append('loadStatus', this.entryExitForm.value.loadStatus);
          formData.append('locationId', this.entryExitForm.value.locationId);
          formData.append('proNumber', this.entryExitForm.value.proNumber);
          formData.append('sequenceNumber', this.entryExitForm.value.sequenceNumber);
          formData.append('spotId', this.entryExitForm.value.spotId);
          formData.append('sub', this.entryExitForm.value.sub);
          formData.append('supplier', this.entryExitForm.value.supplier);
          formData.append('tractorNumber', this.entryExitForm.value.tractorNumber);
          formData.append('type', this.entryExitForm.value.type);
          formData.append('notes', this.entryExitForm.value.notes);
          console.log(formData);
          
          this.manageLocationsService.createEntryExit(this.locationId,this.clientId,formData).subscribe(res=>{
              this.entryExitDialog = false;
              this.entryExitForm.reset();
              if(this.loggedInClient != null || this.loggedInClient != ""){
                this.clientId = this.loggedInClient;
              }
              this.locationId=null;
              this.alertService.alertSuccess(['Entry/Exit Record created successfully']);
              this.getEntryExit(this.query);
              this.entrySubmitted = false;
              this.newCarrier = false;
              this.newTrailer = false;
              this.newSupplier = false;
              this.loader.hide();
          },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
      
    }
    }

    saveTrailer(){

      let data = {
        clientIds: [this.clientId],
        unitNumber: this.entryExitForm.value.fleetId,
        carrier: this.entryExitForm.value.carrier,
        type: "TRAILER"
      };
      this.loader.show();
      this.addFleetService.saveFleet(data)
          .pipe(
            concatMap(fleet => {
              this.entryExitForm.patchValue({
                fleetId: fleet.fleetId,
                supplier: this.selectedSupplierId,
                // driver: this.selectedDriverId
              })
              const formData = new FormData();
              if(this.selectedFile != null && this.selectedFile != undefined){
                formData.append('billOfLandingImage', this.selectedFile);
              }
              formData.append('billOfLandingType', this.entryExitForm.value.billOfLandingType);
              formData.append('carrier', this.entryExitForm.value.carrier);
              formData.append('clientId', this.entryExitForm.value.clientId);
              const formattedDate = this.datePipe.transform(this.entryExitForm.value.dateOfArrival, 'yyyy-MM-dd');
              if (formattedDate) {
                formData.append('dateOfArrival', formattedDate);
              }
              const formattedPickupDate = this.datePipe.transform(this.entryExitForm.value.dateOfPickup, 'yyyy-MM-dd');
              if (formattedPickupDate) {
                formData.append('dateOfPickup', formattedPickupDate);
              }
              const formattedDueAtPlant = this.datePipe.transform(this.entryExitForm.value.dueOfPlant, 'yyyy-MM-dd');
              if (formattedDueAtPlant) {
                formData.append('dueOfPlant', formattedDueAtPlant);
              }
              // formData.append('dateOfArrival', this.entryExitForm.value.dateOfArrival);
              // formData.append('dateOfPickup', this.entryExitForm.value.dateOfPickup);
              formData.append('driver', this.entryExitForm.value.driver);
              // formData.append('dueOfPlant', this.entryExitForm.value.dueOfPlant);
              formData.append('fleetId', this.entryExitForm.value.fleetId);
              formData.append('loadStatus', this.entryExitForm.value.loadStatus);
              formData.append('locationId', this.entryExitForm.value.locationId);
              formData.append('proNumber', this.entryExitForm.value.proNumber);
              formData.append('sequenceNumber', this.entryExitForm.value.sequenceNumber);
              formData.append('spotId', this.entryExitForm.value.spotId);
              formData.append('sub', this.entryExitForm.value.sub);
              formData.append('supplier', this.entryExitForm.value.supplier);
              formData.append('tractorNumber', this.entryExitForm.value.tractorNumber);
              formData.append('type', this.entryExitForm.value.type);
              return this.manageLocationsService.createEntryExit(this.locationId,this.clientId,formData)
            })
          )
          .subscribe(res => {
            this.loader.hide();
            this.entryExitDialog = false;
            this.entryExitForm.reset();
            if(this.loggedInClient != null || this.loggedInClient != ""){
              this.clientId = this.loggedInClient;
            }
            this.locationId=null;
            this.alertService.alertSuccess(['Entry/Exit Record created successfully']);
            this.getEntryExit(this.query);
            this.entrySubmitted = false;
            this.loader.hide();
            this.newTrailer = false;
          }, (error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
          });
    }

    paginate(event){
      this.query.page = event.page;
      this.filterByClient ? this.getEntryExit(this.query,this.filterByClient) :this.getEntryExit(this.query);
    }

    filterFleetsByClient(event){
      this.filterByClient = event.value;
      this.getEntryExit(this.query,event.value);
    }

    exportCsv(){
      if(this.filterByClient){
        window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/fleets/entryExits/export/csv?access_token=${this.accessToken}&location.client.uuid=${this.filterByClient}`, '_blank');
      }else{
        window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/fleets/entryExits/export/csv?access_token=${this.accessToken}`, '_blank');
      }
    }

    exportExcel(){
      if(this.fromExportDate != null && this.toExportDate != null && this.toExportDate >= this.fromExportDate){
        // if(this.filterByClient){
        //   window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/fleets/entryExits/export/excel?access_token=${this.accessToken}&location.client.uuid=${this.filterByClient}`, '_blank');
        // }else{
        //   window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/fleets/entryExits/export/excel?access_token=${this.accessToken}`, '_blank');
        // }

        const url = new URL(`${APP_CONSTANTS.BASE_API_URL}/v1/fleets/entryExits/export/excel`);
        // url.searchParams.append("isActive", "true");
        url.searchParams.append("access_token", this.accessToken);
      
        if (this.filterByClient) {
          url.searchParams.append("location.client.uuid", this.filterByClient);
        }
        if (this.fromExportDate) {
          url.searchParams.append("fromDate", this.fromExportDate);
        }
        if (this.toExportDate) {
          url.searchParams.append("toDate", this.toExportDate);
        }
      
        window.open(url.toString(), '_blank');

        this.isExportClicked = false;
        this.fromExportDate = null;
        this.toExportDate = null;
        this.exportDateValid = false;
        this.exportRangeValid = false;
      }else if(this.toExportDate < this.fromExportDate){
        this.exportRangeValid = true;
        this.exportDateValid = false;
      }else{
        this.exportDateValid = true;
        this.exportRangeValid = false;
      }
    }
    checkIfHotTrailer(fleet) {
      if(fleet.isHotTrailer){
        return `${fleet.unitNumber} - (Hot Trailer)`
      }else{
        return `${fleet.unitNumber}`
      }
    }

    deleteEntryExit(clientId,entryExitId) {
      this.deleteEntryExitDialog = true;
      this.entryExitId = entryExitId;
      this.entryExitClientId = clientId;
    }

    confirmDelete(){
      this.loader.show();
      this.subscription.add(
      this.manageEntryExitService.deleteEntryExit(this.entryExitClientId,this.entryExitId).subscribe(res=>{
          this.deleteEntryExitDialog = false;
          this.entryExitId=undefined;
          this.entryExitClientId=undefined;
          this.alertService.alertSuccess([`Deleted Successfully`]);
          this.getEntryExit(this.query);
          this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    })
      )
    }


    onFleetSelect(event:any)
    {
      this.selectedFleetId = event.fleetId;
       this.entryExitForm.patchValue({
        fleetId:event
      });
      if(this.entryExitForm.value.type == "EXIT"){
        this.getFleetById(event.fleetId);
      }
    }

    onExitSelect(event:any){
      if(event.value == "EXIT" && this.selectedFleetId != undefined){
        this.getFleetById(this.selectedFleetId);
      }
    }

    onDriverSelect(event:any)
    {
      // this.selectedDriverId = event.userId;
       this.entryExitForm.patchValue({
        driver:event.fullName
      });
      console.log(this.entryExitForm.value);
      
    }

    onCarrierSelect(event:any)
    {
      this.selectedCarrierId = event.carrierId;
       this.entryExitForm.patchValue({
        carrier:event
      });
      
    }

    onSupplierSelect(event:any)
    {
      this.selectedSupplierId = event.supplierId;
       this.entryExitForm.patchValue({
        supplier:event
      });
      
    }

    clearFilter(event:any)
    {
      this.entryExitForm.patchValue({
        fleetId:''
      });
    }

    clearCarrier(event:any)
    {
      this.entryExitForm.patchValue({
        carrier:''
      });
    }

    clearSupplierFilter(event:any)
    {
      this.entryExitForm.patchValue({
        supplier:''
      });
    }

    clearDriverFilter(event:any)
    {
      this.entryExitForm.patchValue({
        driver:''
      });
      // this.selectedDriverId = '';
    }

    onFileSelected(event: any) {
      const fileInput = event.target as HTMLInputElement;
      if (fileInput.files && fileInput.files.length > 0) {
        this.selectedFile = fileInput.files[0];
        this.fileText = fileInput.files[0].name;
      }
      console.log(this.fileText);
      
      this.entryExitForm.patchValue({
        billOfLandingImage: this.selectedFile
      })
      console.log(this.selectedFile);
      
    }

    viewUsers(allUserCheck:any = 0){
      if(allUserCheck == 0){
        // this.drivers = [];
      }
      this.manageUsersService.viewUsers(this.maximumSizequery, this.clientId).
        pipe(
          map(res => {
            let users = [];
            for (let user of res.list) {
              let obj = {
                ...user,
                fullName: user.firstName + " " + user.lastName
              };
              users.push(obj);
            }
            return users
          })
        ).subscribe(users => {
          // this.drivers = [];

          users.map(user => {
            if(user.userId == this.loggedInUserId){
              this.updatedByList = [];
              this.updatedByList.push(user);
              this.entryExitForm.patchValue({
                userName: this.updatedByList[0].userId
              });
            }
            if(allUserCheck == 0 && (this.clientId != null && this.clientId != "")){
              console.log("entered", this.clientId);
              
              // for (let role of user["roles"]) {

              //   if (role.roleName === "DRIVER") {
              //     this.drivers.push(user);
              //   }
              // }
            }
            
          });
          console.log("updated",this.updatedByList);
          
      });
    }

    getSuppliers(event:any={}) {
      // this.loader.show();
          this.trailerTrackingService.getSuppliers(this.maximumSizequery, event.query).subscribe(response=>{
              this.supplierList = response.list;
              // this.loader.hide();
          },(error) => {
              // this.loader.hide();
              this.errorService.handleError(error, true);
      });
    }
  
    // getCarriers(event:any = {}) {
      
    //       this.trailerTrackingService.getCarriers(this.maximumSizequery, event.query).subscribe(response=>{
    //           this.carrierList = response.list;
    //           // this.loader.hide();
    //       },(error) => {
    //           // this.loader.hide();
    //           this.errorService.handleError(error, true);
    //     });
    // }

    getCarriers(event) {

      // this.trailerTrackingService.getCarriers(this.query, event.query)
      this.addFleetService.getCarrier(event.query)
        .subscribe(response => {
          this.carrierList = response;
          console.log(this.carrierList);
          this.loader.hide();
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        });
    }

    getDriverList(event:any = {}) {
      this.manageUsersService.viewUsers(this.maximumSizequery, this.clientId, event.query,"DRIVER").
        pipe(
          map(res => {
            let users = [];
            for (let user of res.list) {
              let obj = {
                ...user,
                fullName: user.firstName + " " + user.lastName
              };
              users.push(obj);
            }
            return users
          })
        ).subscribe(users => {
          this.drivers = users;
        });
      
}

    dateFormat(){
      this.entryExitForm.patchValue({
        dateOfPickup:  this.entryExitForm.value.dateOfPickup.toISOString().slice(0, 10)
      })
      
    }

    routeToTrailerTracking(){
      this.router.navigate(['/main/manage-trailer-tracking'])
    }

    showNewTrailerFields(){
      if(this.newTrailer){
        this.newTrailer = false;
      }else{
        this.newTrailer = true;
        
      }
      this.entryExitForm.get('fleetId').reset();
        
    }

    showNewCarrier(){
      if(this.newCarrier){
        this.newCarrier = false;
      }else{
        this.newCarrier = true;
        
      }
      // this.entryExitForm.get('carrier').reset();
      // this.entryExitForm.get('newCarrier').reset();
    }

    addCarrier(){
      this.carrierList.push(this.entryExitForm.value.newCarrier);
      this.entryExitForm.patchValue({
        carrier: this.entryExitForm.value.newCarrier
      });
      this.newCarrier = false;
    }

    showNewSupplier(){
      if(this.newSupplier){
        this.newSupplier = false;
      }else{
        this.newSupplier = true;
        
      }
      this.entryExitForm.get('supplier').reset();
      this.entryExitForm.get('newSupplier').reset();
    }

    addSupplier(){
      let data = {
        supplier: this.entryExitForm.value.newSupplier
      }
      this.trailerTrackingService.createSupplier(data).subscribe(response=>{
        this.getSuppliers();
        this.entryExitForm.patchValue({
          supplier: response.supplierId,
          supplierName: response
        });
        this.selectedSupplierId = response.supplierId;
        this.newSupplier = false;
        this.loader.hide();
      },(error) => {
          this.loader.hide();
          
      });
    }

    getFleetById(fleetId) {
     
      this.addFleetService.getFleetById(fleetId).subscribe(res => {
        this.entryExitForm.patchValue({
          locationId: res.spot?.locationId,
          spotId: res.spot?.spotId
        });
        
        if(res.spot != undefined){
          this.locationId = res.spot.locationId;
          this.getClientSpots(this.maximumSizequery, this.clientId, res.spot.locationId);
        }
        
      }, (error) => {
        this.errorService.handleError(error, true);
      })
    }
    
    viewBol(entry){
      this.selectedBol = entry.billOfLandingImage;
      this.selectedBolType = entry.billOfLandingType;
      this.bolDialog = true;
      // this.selectedImagePath = this.selectedBol[0].imagePath;
    }

    saveTypedData(){
      const typedValue = this.entryExitForm.get('driverName')?.value;

      console.log("Typed value:", typedValue);
    
      if (typedValue && typedValue !== "" && (typedValue.fullName == "" || typedValue.fullName == undefined)) {
        this.entryExitForm.patchValue({
          driver: typedValue
        });
      }
    
  
    }

    addTrailer(){
      let data = {
        clientIds: [this.clientId],
        unitNumber: this.entryExitForm.value.newFleet,
        carrier: this.entryExitForm.value.carrier,
        type: "TRAILER"
      };
      this.loader.show();
      this.addFleetService.saveFleet(data)
          .subscribe(res => {
            this.loader.hide();
          
            const newTrailer = {
              fleetId: res.fleetId,
              fleetAndHotTrailer: res.unitNumber // Adjust the property name based on your API response
            };
      
            if (Array.isArray(this.fleetList)) {
              this.fleetList = [...this.fleetList, newTrailer];  // Add new trailer to the array
            }
            // Patch the form with the new trailer's unit number
            this.entryExitForm.patchValue({
              trailer: newTrailer,
              fleetId: res.fleetId,
            });
            this.selectedFleetId = res.fleetId;
            console.log(this.entryExitForm.value);
            
            this.newTrailer = false;
          }, (error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
          });
    }

    exportPopup(){
      this.isExportClicked = true;
    }

    clearExportPopup(){
      this.isExportClicked = false;
      this.fromExportDate = null;
      this.toExportDate = null;
      this.exportDateValid = false;
      this.exportRangeValid = false;
    }
    
}
