import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { UserAvailabilityService } from './user-availability.service';
import { TokenService } from 'src/app/security/token.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { ErrorService } from 'src/app/error-handling/error.service';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-manage-user-availability',
  templateUrl: './manage-user-availability.component.html',
  styleUrls: ['./manage-user-availability.component.scss']
})
export class ManageUserAvailabilityComponent implements OnInit {

  breadcrumbItems: MenuItem[];
  mondayChecked: boolean = false;
  userId: any;
  userAvailabilityException: any;
  addExceptionFormValidity: boolean = true;
  newOffDayDialog: boolean = false;
  newExceptionForm: FormGroup;
  isSubmitted: boolean = false;
  name: string = "";
  email: any;
  availabilityList: any;
  availabilityList_data: any = [
    {
      "dayOfWeek": "Monday",
      "isActive": false,
      "startingTime": null,
      "endingTime": null,
      "breakStartingTime": null,
      "breakEndingTime": null,
      "userAvailabilityId": null,
      "isChanged": false,
      "isRequiredError": false
    },
    {
      "dayOfWeek": "Tuesday",
      "isActive": false,
      "startingTime": null,
      "endingTime": null,
      "breakStartingTime": null,
      "breakEndingTime": null,
      "userAvailabilityId": null,
      "isChanged": false,
      "isRequiredError": false
    },
    {
      "dayOfWeek": "Wednesday",
      "isActive": false,
      "startingTime": null,
      "endingTime": null,
      "breakStartingTime": null,
      "breakEndingTime": null,
      "userAvailabilityId": null,
      "isChanged": false,
      "isRequiredError": false
    },
    {
      "dayOfWeek": "Thursday",
      "isActive": false,
      "startingTime": null,
      "endingTime": null,
      "breakStartingTime": null,
      "breakEndingTime": null,
      "userAvailabilityId": null,
      "isChanged": false,
      "isRequiredError": false
    },
    {
      "dayOfWeek": "Friday",
      "isActive": false,
      "startingTime": null,
      "endingTime": null,
      "breakStartingTime": null,
      "breakEndingTime": null,
      "userAvailabilityId": null,
      "isChanged": false,
      "isRequiredError": false
    },
    {
      "dayOfWeek": "Saturday",
      "isActive": false,
      "startingTime": null,
      "endingTime": null,
      "breakStartingTime": null,
      "breakEndingTime": null,
      "userAvailabilityId": null,
      "isChanged": false,
      "isRequiredError": false
    },
    {
      "dayOfWeek": "Sunday",
      "isActive": false,
      "startingTime": null,
      "endingTime": null,
      "breakStartingTime": null,
      "breakEndingTime": null,
      "userAvailabilityId": null,
      "isChanged": false,
      "isRequiredError": false
    }
  ];

  type: any = [
    {
      name: "Day Off",
      code: "DAY_OFF"
    },
    {
      name: "Working Day",
      code: "WORKING_DAY"
    }
  ];
  menuItems: any = [];
  breakStartTime : any;
  breakEndTime : any;
  errorMessage :string;
  errorMessageDialog : boolean = false;

  constructor(
    private userAvailability: UserAvailabilityService,
    private tokenService: TokenService,
    private fb: FormBuilder,
    private loader: AppLoaderService,
    private alertService: AppAlertService,
    private errorService: ErrorService,
    private route: ActivatedRoute
  ) {
    this.newExceptionForm = this.fb.group({
      date: ['', Validators.required],
      userId: [''],
      type: ['', Validators.required],
      startingTime: ['', Validators.required],
      endingTime: ['', Validators.required],
      breakStartingTime:[''],
      breakEndingTime:['']
    });
  }

  ngOnInit(): void {

    this.newExceptionForm.patchValue({
      breakStartingTime: null,
      breakEndingTime: null,    
    }); 
    this.breadcrumbItems = [];
    this.breadcrumbItems.push({ label: 'Users', routerLink: '../manage-users' });
    this.breadcrumbItems.push({ label: 'User Availability' });

    this.route.queryParams.subscribe(params => {
      this.userId = params['userId'];

    });


    let data = JSON.parse(localStorage.getItem('data')!);
    this.name = data.name;
    this.email = data.email;

    this.getUserAvailabilityDetails();
    this.getUserAvailabilityException();
  }

  getUserAvailabilityDetails() {
    this.loader.show();

    this.userAvailability.getUserAvailability(this.userId).subscribe(res => {
      let availability = res;
      if (availability.length > 0) {
        this.name = availability[0].firstName + " " + availability[0].lastName;
        this.email = availability[0].email;
      }
      this.availabilityList = [];
      this.availabilityList = JSON.parse(JSON.stringify(this.availabilityList_data));
      availability.forEach(item => {
        let addedAvailability = this.availabilityList.filter((avl: any) => { return avl.dayOfWeek.toUpperCase() == item.dayOfWeek });

        let [hours, minutes] = item.startingTime.split(':').map(Number);
        let date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);
        date.setMilliseconds(0);
        addedAvailability[0].startingTime = date;

        let [hours_end, minutes_end] = item.endingTime.split(':').map(Number);
        let date_end = new Date();
        date_end.setHours(hours_end);
        date_end.setMinutes(minutes_end);
        date_end.setSeconds(0);
        date_end.setMilliseconds(0);
        addedAvailability[0].endingTime = date_end;

        if(item.breakStartingTime != null)
        {

          let [breakHours, breakMinutes] = item.breakStartingTime.split(':').map(Number);
          let breakDate = new Date();
          breakDate.setHours(breakHours);
          breakDate.setMinutes(breakMinutes);
          breakDate.setSeconds(0);
          breakDate.setMilliseconds(0);
          addedAvailability[0].breakStartingTime = breakDate;

        }
        
        if(item.breakEndingTime != null)
        {

          let [breakHours_end, breakMinutes_end] = item.breakEndingTime.split(':').map(Number);
          let breakDate_end = new Date();
          breakDate_end.setHours(breakHours_end);
          breakDate_end.setMinutes(breakMinutes_end);
          breakDate_end.setSeconds(0);
          breakDate_end.setMilliseconds(0);
          addedAvailability[0].breakEndingTime = breakDate_end;

        }
        
        addedAvailability[0].isActive = item.isActive;
        addedAvailability[0].userAvailabilityId = item.userAvailabilityId;
      });


      if (availability.length == 0) {
        this.availabilityList.forEach(avl => {
          avl.endingTime = null;
          avl.startingTime = null;
          avl.userAvailabilityId = null;
        });
      }
      this.loader.hide();
    }, (error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
    });
  }

  getUserAvailabilityException() {
    this.userAvailability.getUserAvailabilityException(this.userId)
      .pipe(
        map(res => res.map(item => ({
          ...item,
          type: item.type.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
        })))
      )
      .subscribe(
        res => {
          this.userAvailabilityException = res;
          console.log("Exceptions", this.userAvailabilityException);
        },
        error => {
          // this.loader.hide();
          // this.errorService.handleError(error, true);
        }
      );
  }

  // saveUserAvailability(item: any) {


  //   if (item.startingTime != null && item.endingTime != null) {
  //     item.count_i++;

  //       setTimeout(() => {
  //         item.count_j++;
  //         if(item.count_i == item.count_j){
  //           let data = {
  //             userId: this.userId,
  //             dayOfWeek: item.dayOfWeek.toUpperCase(),
  //             startingTime: `${item.startingTime.getHours().toString().padStart(2, '0')}:${item.startingTime.getMinutes().toString().padStart(2, '0')}`,
  //             endingTime: `${item.endingTime.getHours().toString().padStart(2, '0')}:${item.endingTime.getMinutes().toString().padStart(2, '0')}`,

  //           }
  //           this.userAvailability.saveUserAvailability(data).subscribe(res => {
  //             this.getUserAvailabilityDetails();
  //           }, (error) => {
  //             this.errorService.handleError(error, true);
  //           });
  //         }
  //       }, 1000);
  //   }else if(item.startingTime == null && item.endingTime == null && item.userAvailabilityId != null){
  //     this.userAvailability.deleteUserAvailability(item.userAvailabilityId).subscribe(res => {
  //       // this.getUserAvailabilityDetails();
  //       item.userAvailabilityId = null;
  //     }, (error) => {
  //       this.errorService.handleError(error, true);
  //     });
  //   }

  // }

  saveUserAvailability() {
    
    let hasError: boolean = false;
    let requiredFields = this.availabilityList.filter((x: any) => { return (x.startingTime != null && x.endingTime == null) || (x.startingTime == null && x.endingTime != null); });
    if (requiredFields.length > 0) {
      requiredFields.map((x: any) => { x.isRequiredError = true; });
    } else {
      let observables = [];
      let newUserAvailabilities = [];
      let toBeRemovedUserAvailabilitiesId = [];
      this.availabilityList.forEach(item => {
        if (item.isChanged) {
        if((item.breakStartingTime != null && item.breakEndingTime == null) || (item.breakStartingTime == null && item.breakEndingTime != null))
          {
            this.errorMessage = "select both break starting time and break ending time";
            this.errorMessageDialog = true;
            // alert("select both break starting time and break ending time");
            hasError = true;
              
          }
        if(item.startingTime < item.endingTime)
        {
          if(item.breakStartingTime != null && item.breakEndingTime != null)
          {
            if(item.breakStartingTime < item.startingTime || item.breakStartingTime > item.endingTime || 
                   item.breakEndingTime < item.startingTime || item.breakEndingTime > item.endingTime)
                   {
                    this.errorMessage = "Break time should be in between starting time and ending time";
                    this.errorMessageDialog = true;
                    hasError = true;
                   }
                   if(item.breakStartingTime > item.breakEndingTime)
                   {
                    this.errorMessage = "Break starting time should be before break ending time";
                    this.errorMessageDialog = true;
                    hasError = true;
                   }
          }
        }

        if(item.startingTime > item.endingTime)
        {
          if(item.breakStartingTime != null && item.breakEndingTime != null)
          {
            if(item.breakStartingTime < item.startingTime && item.breakStartingTime > item.endingTime)
              {
               this.errorMessage = "Break time should be in between starting time and ending time";
               this.errorMessageDialog = true;
               hasError = true;
              }
  
              if(item.breakEndingTime < item.startingTime && item.breakEndingTime > item.endingTime)
                {
                 this.errorMessage = "Break time should be in between starting time and ending time";
                 this.errorMessageDialog = true;
                 hasError = true;
                }
          }
          

        }

        
        // if(item.breakStartingTime > item.breakEndingTime)
        // {
        //   this.errorMessage = "Break starting time should be before break ending time";
        //   this.errorMessageDialog = true;
        //   // alert("Break starting time should be before break ending time");
        //   hasError = true;
        // }
        // if(item.breakStartingTime != null && item.breakEndingTime != null)
        // {
        //   if(item.breakStartingTime < item.startingTime || item.breakStartingTime > item.endingTime || 
        //     item.breakEndingTime < item.startingTime || item.breakEndingTime > item.endingTime)
        //  {
           
        //   this.errorMessage = "Break time should be in between starting time and ending time";
        //   this.errorMessageDialog = true;
        //   //  alert("break time should be in between starting time and ending time")
        //    hasError = true;
        //  }
        // }
        }
      })
      if(hasError)
      {
        return;
      }

      this.availabilityList.forEach(item => {
        if (item.isChanged) {
          if (item.startingTime != null && item.endingTime != null) {
            let data = {
              userId: this.userId,
              dayOfWeek: item.dayOfWeek.toUpperCase(),
              startingTime: `${item.startingTime.getHours().toString().padStart(2, '0')}:${item.startingTime.getMinutes().toString().padStart(2, '0')}`,
              endingTime: `${item.endingTime.getHours().toString().padStart(2, '0')}:${item.endingTime.getMinutes().toString().padStart(2, '0')}`,
              breakStartingTime: null,
              breakEndingTime: null,
              active: item.isActive
            }
            if(item.breakStartingTime != null && item.breakEndingTime != null)
            {

              data.breakStartingTime = `${item.breakStartingTime.getHours().toString().padStart(2, '0')}:${item.breakStartingTime.getMinutes().toString().padStart(2, '0')}`;
              data.breakEndingTime = `${item.breakEndingTime.getHours().toString().padStart(2, '0')}:${item.breakEndingTime.getMinutes().toString().padStart(2, '0')}`;
            }
             newUserAvailabilities.push(data);
            // observables.push(
            //   this.userAvailability.saveUserAvailability(data).pipe(
            //     catchError(error => {
            //       this.errorService.handleError(error, true);
            //       return of(null); // handle the error but continue the forkJoin
            //     })
            //   )
            // );
          } else if (item.startingTime == null && item.endingTime == null && item.userAvailabilityId != null) {
            observables.push(
              this.userAvailability.deleteUserAvailability(item.userAvailabilityId).pipe(
                catchError(error => {
                  this.errorService.handleError(error, true);
                  return of(null); // handle the error but continue the forkJoin
                }),
                finalize(() => {
                  item.userAvailabilityId = null;
                })
              )
            );
          }
        }
      });
      if(newUserAvailabilities.length > 0)
      {
        observables.push(
          this.userAvailability.saveUserAvailability(newUserAvailabilities).pipe(
            catchError(error => {
              this.errorService.handleError(error, true);
              return of(null); // handle the error but continue the forkJoin
            })
          )
        );
      }
     
      if (observables.length > 0) {
        forkJoin(observables).subscribe(() => {
          this.getUserAvailabilityDetails();
        });
      }
    }
  }

  availabilityChanged(item: any) {
    item.isChanged = true;
  }

  formatTime(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  saveNewException() {

    
    this.isSubmitted = true;
    if (this.newExceptionForm.invalid) {
      this.newExceptionForm.markAllAsTouched();
    } else {
      this.loader.show();
      
      // if(this.newExceptionForm.value.startingTime > this.newExceptionForm.value.endingTime)
      // {
      //   this.errorMessage = "Starting time should be before ending time";
      //   // this.errorMessageDialog = true;
      //     // alert("Starting time should be before ending time");
      //     this.loader.hide();
      //     return;
      // }

      // if(this.newExceptionForm.value.breakStartingTime > this.newExceptionForm.value.breakEndingTime)
      // {

      //     this.errorMessage = "Break starting time should be before break ending time";
      //     // this.errorMessageDialog = true;
      //     // alert("Break starting time should be before break ending time");
      //     this.loader.hide();
      //     return;
      // }
     
      // if(this.newExceptionForm.value.breakStartingTime != null && this.newExceptionForm.value.breakEndingTime != null)
      // {
      //   if(this.newExceptionForm.value.breakStartingTime < this.newExceptionForm.value.startingTime ||
      //      this.newExceptionForm.value.breakStartingTime > this.newExceptionForm.value.endingTime || 
      //      this.newExceptionForm.value.breakEndingTime < this.newExceptionForm.value.startingTime || 
      //      this.newExceptionForm.value.breakEndingTime > this.newExceptionForm.value.endingTime)
      //    {
          
      //     this.errorMessage = "Break time should be in between starting time and ending time";
      //     // this.errorMessageDialog = true;
      //     //  alert("break time should be in between starting time and ending time");
      //      this.loader.hide();
      //      return;
      //    }
      // }

      if((this.newExceptionForm.value.breakStartingTime == null && this.newExceptionForm.value.breakEndingTime != null) || (this.newExceptionForm.value.breakStartingTime != null && this.newExceptionForm.value.breakEndingTime == null))
      {
        this.addExceptionFormValidity = false;
        this.loader.hide();
        return;
      }

      if((this.newExceptionForm.value.breakStartingTime != null && this.newExceptionForm.value.breakStartingTime != '') && 
         (this.newExceptionForm.value.breakEndingTime != null && this.newExceptionForm.value.breakEndingTime != ''))
      {
          
          this.newExceptionForm.patchValue({
            breakStartingTime: `${this.newExceptionForm.value.breakStartingTime.getHours().toString().padStart(2, '0')}:${this.newExceptionForm.value.breakStartingTime.getMinutes().toString().padStart(2, '0')}`,
            breakEndingTime: `${this.newExceptionForm.value.breakEndingTime.getHours().toString().padStart(2, '0')}:${this.newExceptionForm.value.breakEndingTime.getMinutes().toString().padStart(2, '0')}`,    
          });       
      }

      this.newExceptionForm.patchValue({
        userId: this.userId,
        date: this.normalizeDate(this.newExceptionForm.value.date),
        startingTime: `${this.newExceptionForm.value.startingTime.getHours().toString().padStart(2, '0')}:${this.newExceptionForm.value.startingTime.getMinutes().toString().padStart(2, '0')}`,
        endingTime: `${this.newExceptionForm.value.endingTime.getHours().toString().padStart(2, '0')}:${this.newExceptionForm.value.endingTime.getMinutes().toString().padStart(2, '0')}`,
      });
      this.userAvailability.saveNewAvailabilityException(this.newExceptionForm.value).subscribe(res => {
        this.addExceptionFormValidity = true;
        this.newOffDayDialog = false;
        this.isSubmitted = false;
        this.newExceptionForm.reset();
        this.loader.hide();
        this.getUserAvailabilityException();
      }, (error) => {
        this.loader.hide();
        this.alertService.alertError(["Please select a different date as it is already added in the system."])
        // this.errorService.handleError(error, true);
      });
    }
  }

  availabilityActivated(item:any){
    if(item.userAvailabilityId != null){
      let data = {
        active: item.isActive
      }
      this.userAvailability.saveAvailabilityActive(data,item.userAvailabilityId).subscribe(res => {
       
      }, (error) => {
        this.loader.hide();
        item.isActive = item.isActive;
        this.alertService.alertError(["Please try again later."])
        // this.errorService.handleError(error, true);
      });
    }
  }

  hideDialog() {
    this.addExceptionFormValidity = true;
    this.newOffDayDialog = false;
    this.newExceptionForm.reset();
    this.isSubmitted = false;
  }

  hideDialog1()
  {
    this.errorMessage = "";
    this.errorMessageDialog = false;
  }

  toggleMenu(item: any) {
    this.menuItems = [
      // {
      //   label: 'Edit',
      //   icon: 'pi pi-fw pi-pencil',
      //   // command: () => this.showEditDialog(availabilityException)
      // },
      {
        label: 'Delete',
        icon: 'pi pi-fw pi-trash',
        command: () => this.onShowDeleteDialog(item)
      }
    ];
  }

  onShowDeleteDialog(item: any) {
    this.userAvailability.deleteAvailabilityException(item.userAvailabilityExceptionId).subscribe(res => {
      this.newOffDayDialog = false;
      this.newExceptionForm.markAsUntouched();
      this.newExceptionForm.reset();
      this.getUserAvailabilityException();
    }, (error) => {
      // this.loader.hide();
      // this.errorService.handleError(error, true);
    });
  }

  normalizeDate(date: Date): Date {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const normalizedDate = new Date(date.getTime() - userTimezoneOffset);
    return new Date(normalizedDate.toISOString().split('T')[0]);
  }


}
