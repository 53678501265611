import { Component, OnInit, OnDestroy } from '@angular/core';
import { Product } from '../../api/product';
import { ProductService } from '../../service/productservice';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { interval, map, Subscription } from 'rxjs';
import { ManageUsersService } from './manage-users.service';
import { TokenService } from 'src/app/security/token.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { ManageClientsService } from '../manage-clients/manage-clients.service';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { ErrorService } from 'src/app/error-handling/error.service';
import { ManageOvertimeUsersService } from '../manage-overtime-users/manage-overtime-users.service';
import * as moment from 'moment';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  providers: [MessageService, ConfirmationService],
  styleUrls: ['../../../assets/demo/badges.scss','./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit {

//     userList = [
//         {
//             id:"1",
//             firstName: 'Chris',
//             lastName: 'D',
//             email: 'chris@maildrop.cc',
//             phone: '1234567890',
//             role: 'Supervisor',
//             status:'Active',
//             client:'Dynacraft '
//         },
//         {
//           id:"2",
//           firstName: 'Scott ',
//           lastName: 'M',
//           email: 'scott@maildrop.cc',
//           phone: '1234567890',
//           role: 'Driver',
//           status:'Inactive',
//           client:'Dynacraft '
//       },
//       {
//         id:"3",
//         firstName: 'Jack ',
//         lastName: 'M',
//         email: 'jack@maildrop.cc',
//         phone: '1234567890',
//         role: 'Driver',
//         status:'Active',
//         client:'Dynacraft '
//     },
//     {
//       id:"4",
//       firstName: 'Michael',
//       lastName: 'H',
//       email: 'michael@maildrop.cc',
//       phone: '1234567890',
//       role: 'Yard Spotter',
//       status:'Inactive',
//       client:'Dynacraft '
//   }
//     ];
    cols: any[];
    statuses: any[];
    breadcrumbItems: MenuItem[];
    dropdownStatus: any;
    loading: boolean;
    query:IpagedQuery;
    totalRecords: any;
    userList = [];
    subscription = new Subscription();
    loggedInUserId: { field: string; header: string; }[];
    resetPasswordModal = false;
    resetPasswordForm: FormGroup;
    userId: any;
    password;
    clientQuery: { isActive: boolean; size: number; page: number; };
    clientList: any;
    filterByClient: any;
  userType: string;
  userRoles: any;
  i:number;// 
  j:number;
  accessToken: string;
  items: MenuItem[];
  hideButtonsIfDriver:boolean = false;
  hideButtonsIfGuard:boolean = false;
  hideButtonsIfSpotter:boolean = false;
  hideButtonsIfSupervisor:boolean = false;
  searchboxValue:string;
  selectedRole:string;
  timerSubscription: any;
  timer2Subscription:any;
  refreshTimeCount: number = 0;
  addOverTimeModal : boolean = false;
  deleteOverTimeModal : boolean = false;
  selectedUserIdForAddingOverTime : string;
  selectedUserIdForRemovingOverTime : string;
  
  roleList = [
    { name: 'Admin', code: 'ADMIN' },
    { name: 'Client', code: 'CLIENT' },
    { name: 'Supervisor', code: 'SUPERVISOR' },
    { name: 'Driver ', code: 'DRIVER' },
    { name: 'Guard', code: 'GUARD' },
    { name: 'Yard Spotter', code: 'SPOTTER' },
    { name: 'IT', code: 'IT' }
];
    constructor(
        private router:Router,
        private manageUsersService:ManageUsersService,
        private tokenService:TokenService,
        private fb : FormBuilder,
        private loader : AppLoaderService,
        private alertService : AppAlertService,
        private manageClientsService:ManageClientsService,
        private errorService:ErrorService,
        private overtimeUsersService: ManageOvertimeUsersService
    ) {
        // this.resetPasswordForm = this.fb.group({
        //     resetPassword: ['',Validators.required],
        // });


        this.items = [
          {
              label: 'Excel',
              icon: 'pi pi-download',
              command: () => {
                  this.exportExcel();
              }
          }
          // ,
          // {
          //     label: 'CSV',
          //     icon: 'pi pi-download',
          //     command: () => {
          //         this.exportCsv();
          //     }
          // }
      ];
    }

    ngOnInit() {

      this.i=0;
      this.j=0;

      this.userRoles = this.tokenService.getUserRoles();
      if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)){
        this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
      }
      if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_DRIVER))
      {
          this.hideButtonsIfDriver = true;
          this.userType = APP_CONSTANTS.USER_ROLES.ROLE_DRIVER;
      }
      if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_GUARD))
      {
          this.hideButtonsIfGuard = true;
          this.userType = APP_CONSTANTS.USER_ROLES.ROLE_GUARD;
      }
      if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SPOTTER))
      {
          this.hideButtonsIfSpotter = true;
          this.userType = APP_CONSTANTS.USER_ROLES.ROLE_SPOTTER;
      }
      if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR))
      {
          this.hideButtonsIfSupervisor = true;
          this.userType = APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR;
      }

      if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_ADMIN)) {
        this.userType = APP_CONSTANTS.USER_ROLES.ROLE_ADMIN;
      }

      if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_IT)) {
        this.userType = APP_CONSTANTS.USER_ROLES.ROLE_IT;
      }
      this.accessToken = this.tokenService.getAccessToken();
        this.query = {isActive:true,size:100,page:0};
        this.getUserList(this.query);
        this.loggedInUserId = this.tokenService.getUserId();
        this.clientQuery = {isActive:true,size:1000,page:0};
        this.getClientList(this.clientQuery);

        this.cols = [
            { field: 'fisrtName', header: 'First Name' },
            { field: 'lastName', header: 'Last Name' },
            { field: 'email', header: 'Email' },
            { field: 'phone', header: 'Phone' }
        ];

        this.dropdownStatus = [
            {name:"Active",code:true},
            {name:"Inactive",code:false},
        ]

        this.breadcrumbItems = [];
        this.breadcrumbItems.push({ label: 'Users'});

        this.updateUserData();
    }



    getClientList(query) {
        this.subscription.add(
          this.manageClientsService.viewClients(query).subscribe(response=>{
            this.clientList = response.list;
          })
        )
    }

    filterUsersByClient(event){
        this.filterByClient = event.value;
        this.getUserList(this.query,this.filterByClient,this.searchboxValue,this.selectedRole);
      }

    getUserList(query: IpagedQuery,clientId?:any,firstName?:string,roleName?:string, hideloader?:boolean) {
      let obj;
      if(!hideloader){
        this.loading = true;
      }    
        this.subscription.add(
          this.manageUsersService.viewUsers(query,clientId,firstName,roleName)
          .pipe(
            map(users=>{
              let userList = [];
              for(let user of users.list){
                if(user.idleTime != null)
                {
                  
                }
                obj = {
                  ...user,
                  idleTime: user.idleTime,
                  idleTimeSinceSeconds: this.elapsedTime(user.idleTime),
                  pendingOverTime: user.pendingOverTime,
                  pendingOverTimeSinceSeconds: this.elapsedTime(user.pendingOverTime),
                  pendingBreakTime: user.pendingBreakTime, 
                  pendingBreakTimeSinceSeconds: this.elapsedTime(user.pendingBreakTime)
                 // spot.emptiedSinceSeconds !== null ?  moment.utc(spot.emptiedSinceSeconds*1000).format('HH:mm:ss') : spot.emptiedSinceSeconds
                }
                userList.push(obj);
              }
              return {
                ...users,
                list:userList
              }
            })
          )
          .subscribe(response=>{
            // this.userList = response.list.filter(el=> el.userId !== this.loggedInUserId);
            this.userList = response.list
            if(this.refreshTimeCount == 0){
              this.startTimer();
              this.refreshTimeCount++;
            }
            if(this.userType == APP_CONSTANTS.USER_ROLES.ROLE_CLIENT){
              this.userList = this.userList.filter(user=>{
                for(let role of user.roles){
                  if(role.roleName == "CLIENT"){
                    return user;
                  }
                }
              });
              
            }

            if(this.userType == APP_CONSTANTS.USER_ROLES.ROLE_DRIVER || this.userType == APP_CONSTANTS.USER_ROLES.ROLE_GUARD || this.userType == APP_CONSTANTS.USER_ROLES.ROLE_SPOTTER){
              
              
              this.userList = this.userList.filter(user=>{
                if(user.userId === this.loggedInUserId)
                 return user;
              });
              
            }
            
            this.totalRecords = response.totalElements;
            this.loading = false;
          },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
        ) 
    }

    resetPassword(userId : any){
        this.loader.show();
        this.manageUsersService.resetpassword(userId).subscribe(res=>{
        this.loader.hide();
        this.alertService.alertSuccess(['password reset successfully']);
        this.password = res.password;
        this.resetPasswordModal = true;
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    })
    }

    routeToAddUser(){
        this.router.navigate(['/main/add-user'])
    }

    routeToEditUser(userId){
        this.router.navigate(['/main/add-user'],{queryParams:{userId}})
    }
    routeToUserAvailability(user){

      let data = {
        name: user.firstName + " " + user.lastName,
        email: user.email
      }
      localStorage.setItem('data', JSON.stringify(data));

        this.router.navigate(['/main/manage-user-availability'], { queryParams: { userId: user.userId } });
    }
    routeToOvertimeUsers(){
      this.router.navigate(['/main/manage-overtime-users'])
  }

    paginate(event){
        this.query.page = event.page;
        this.filterByClient ? this.getUserList(this.query,this.filterByClient) : this.getUserList(this.query);
    }
    
    ngOnDestroy(): void {  
        this.stopUpdatingUser();
        this.stopTimer(); 
        this.subscription.unsubscribe();
    }

    filterUserByStatus(event){
        this.query.isActive = event.value;
        this.getUserList(this.query,this.filterByClient,this.searchboxValue,this.selectedRole);
    }

    exportCsv(){
      if(this.filterByClient){
        window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/users/export/csv?access_token=${this.accessToken}&isActive=${this.query.isActive}&clients.uuid=${this.filterByClient}`, '_blank');
      }else{
        window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/users/export/csv?access_token=${this.accessToken}&isActive=${this.query.isActive}`, '_blank');
      }
    }

    exportExcel(){
      if(this.filterByClient){
        window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/users/export/excel?access_token=${this.accessToken}&isActive=${this.query.isActive}&clients.uuid=${this.filterByClient}`, '_blank');
      }else{
        window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/users/export/excel?access_token=${this.accessToken}&isActive=${this.query.isActive}`, '_blank');
      }
    }


    activateUser(userId:string)
    {
      
      this.manageUsersService.activateInactiveUser(userId).subscribe(res=>{
        this.loader.hide();
        this.query.isActive = false;
        this.getUserList(this.query);
        this.alertService.alertSuccess(['Email has been sent to user for account activation']);
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    })
    }

    search()
    {
      this.i++;
      setTimeout(() => {
        this.j++;
        if(this.i == this.j)
        {
          this.getUserList(this.query,this.filterByClient,this.searchboxValue,this.selectedRole);
        }       
    }, 1000);
    }

    filterUserByRole(event:any){

     this.selectedRole = event.value;
     this.getUserList(this.query,this.filterByClient,this.searchboxValue,this.selectedRole);

    }

    hasSupervisorRole(user: any): boolean {
      if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR))
      {
        return user.roles.some((role: any) => role.roleName === 'SUPERVISOR');
      }
       return false;
    }
    
    showAddOverTimeModal(userId: any){
      this.addOverTimeModal = true;
      this.selectedUserIdForAddingOverTime = userId;
      // this.overtimeUsersService.saveOvertimeUsers(userId).subscribe(res=>{
      //   this.alertService.alertSuccess(["Successfully added to overtime"]);
      //   this.getUserList(this.query,this.filterByClient,this.searchboxValue,this.selectedRole);
      //  this.loader.hide();
      // },(error) => {
      //   this.loader.hide();
      //   this.errorService.handleError(error, true);
      // })
    }

    showDeleteOverTimeModal(userId:any){
      this.deleteOverTimeModal = true;
      this.selectedUserIdForRemovingOverTime = userId;
      // this.overtimeUsersService.deleteOvertimeUsers(userId).subscribe(res=>{
      //   this.alertService.alertSuccess(["Successfully stopped overtime"]);
      //   this.getUserList(this.query,this.filterByClient,this.searchboxValue,this.selectedRole);
      //  this.loader.hide();
      // },(error) => {
      //   this.loader.hide();
      //   this.errorService.handleError(error, true);
      // })
    }

    hideAddOverTimeModal(){
    
      this.addOverTimeModal = false;
      this.selectedUserIdForAddingOverTime = null;
    }

    hideDeleteOverTimeModal(){
      
      this.deleteOverTimeModal = false;
      this.selectedUserIdForRemovingOverTime = null;
    }

    addOverTimeForUser()
    {
        this.addOverTimeModal = false;
        this.overtimeUsersService.saveOvertimeUsers(this.selectedUserIdForAddingOverTime).subscribe(res=>{ 
        this.alertService.alertSuccess(["Successfully added to overtime"]);
        this.getUserList(this.query,this.filterByClient,this.searchboxValue,this.selectedRole);
        this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    }

    deleteOverTimeForUser()
    {
        this.deleteOverTimeModal = false;
        this.overtimeUsersService.deleteOvertimeUsers(this.selectedUserIdForRemovingOverTime).subscribe(res=>{        
        this.alertService.alertSuccess(["Successfully stopped overtime"]);
        this.getUserList(this.query,this.filterByClient,this.searchboxValue,this.selectedRole);
        this.loader.hide();
        },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    }

    elapsedTime(elapsedTime) {
      
      if (elapsedTime !== undefined) {
        elapsedTime = parseInt(elapsedTime) //because moment js dont know to handle number in string format
        //  var hours = Math.floor(moment.duration(elapsedTime,'seconds').asHours()).toLocaleString();
        var days = Math.floor(moment.duration(elapsedTime, 'seconds').asDays()).toLocaleString();
        var hours = moment.duration(elapsedTime, 'seconds').hours().toLocaleString();
        var minutes = moment.duration(elapsedTime, 'seconds').minutes().toLocaleString();
        var seconds = moment.duration(elapsedTime, 'seconds').seconds().toLocaleString();
        days = days.length == 1 ? "0" + days : days;
        hours = hours.length == 1 ? "0" + hours : hours;
        minutes = minutes.length == 1 ? "0" + minutes : minutes;
        seconds = seconds.length == 1 ? "0" + seconds : seconds;
        var format = days + ":" + hours + ":" + minutes + ":" + seconds;
        //Math.floor(moment.duration(seconds,'seconds').asHours()) + ':' + moment.duration(seconds,'seconds').minutes() + ':' + moment.duration(seconds,'seconds').seconds();
        return format;
      } else {
        return elapsedTime;
      }
    }


    private startTimer() {
      this.timerSubscription = interval(1000).subscribe(() => {
        this.updateTimers();
      });
    }
  
    private stopTimer() {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
    }
  
    private updateTimers(){
      this.userList.map(item => {
        if(item.idleTimeSinceSeconds != undefined && item.idleTimeSinceSeconds != null && item.idleTimeSinceSeconds != '' && item.isOnBreak != true){
          item.idleTime++;
          item.idleTimeSinceSeconds = this.elapsedTime(item.idleTime);
        }
        if(item.pendingOverTimeSinceSeconds != undefined && item.pendingOverTimeSinceSeconds != null && item.pendingOverTimeSinceSeconds != ''){

          if(item.pendingOverTime > 0)
          {
            item.pendingOverTime--;
            item.pendingOverTimeSinceSeconds = this.elapsedTime(item.pendingOverTime);
          }
          else
          {
            item.pendingOverTimeSinceSeconds = null;
          }
          
        }
        if(item.pendingBreakTimeSinceSeconds != undefined && item.pendingBreakTimeSinceSeconds != null && item.pendingBreakTimeSinceSeconds != ''){
          if(item.pendingBreakTime > 0)
          {
            item.pendingBreakTime--;
            item.pendingBreakTimeSinceSeconds = this.elapsedTime(item.pendingBreakTime);
          }
          else
          {
             item.pendingBreakTimeSinceSeconds = null;
          }
          
        }
      });
    }

    private updateUserData()
    {

        this.timer2Subscription = interval(30000).subscribe(() => {
        this.getUserList(this.query,this.filterByClient,this.searchboxValue,this.selectedRole,true);  
      });
    }

    private stopUpdatingUser() {

      if (this.timer2Subscription) {
        this.timer2Subscription.unsubscribe();
      }
    }
   

  }

